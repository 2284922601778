<template>
    <div v-if="_mobile" style="width: 100%; float: left; margin-top: 20px">
        <div class="mobile-fee-low-inner">
            <div class="mobile-fee-low-title">
                <div style="font-weight: 600;">Мгновенный расчет комиссии</div>              
            </div>
            <div class="mobile-fee-low-icon" >
                <img src="/img/low-fee.png" style="width: 100%"/>
            </div>
        </div>

        <div class="mobile-fee-low-inner-any">
            <div class="mobile-fee-low-title">
                <div style="font-weight: 600;color: #191B1E;">Любая валюта</div>              
            </div>
        </div>
    </div>

    <div v-if="!_mobile" style="width: 100%; float: left; margin-top: 34px;">
        <div class="main-fee-low-inner">          
            <div style="word-wrap: break-word; white-space: initial; margin-right: 52px; margin-left: 52px; margin-top: 49px; color: #191B1E; font-size: 37.411px; font-style: normal;  letter-spacing: 0.2px;">
                <div style="font-weight: 600;">{{ getLocale('home_transfers_3') }}</div>                      
            </div>   
            
            <div style="position: absolute; width: 195px; height: 185px; right: 51px; bottom: 48px">
                <img src="/img/low-fee.png" style="width: 100%"/>
            </div>          
        </div>

        <div style="position: relative; width: calc(50% - 18px); border-radius: 22.447px; background-color: white;; float: left; height: 534px; margin-left: 36px;">
            <div style="width: 100%; height: 100%; background-image: url('/img/any-currency.png'); background-repeat: no-repeat; background-position: bottom 48px left; background-size: 100% auto; ">
                <div style="color: #191B1E; word-wrap: break-word; white-space: initial; margin-right: 52px; margin-left: 52px; margin-top: 49px; color: #191B1E; font-size: 37.411px; font-style: normal; font-weight: 600; line-height: 44.893px;  letter-spacing: 0.2px;">
                    {{ getLocale('home_transfers_4') }}
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.mobile-fee-low-inner-any {
    margin-top: 20px; position: relative; margin-left: 20px; width: calc(100% - 40px); float: left; border-radius: calc(37.98px / 2); height: calc(379.8px / 2); background: white;
    background-image: v-bind(_getBG); background-repeat: no-repeat; 
    background-position: bottom calc(57px / 2) center; 
    background-size: 120% auto;
}
.mobile-fee-low-title {
    margin-right: calc(52px / 2);  margin-left: calc(52px / 2); margin-top: calc(52px / 2) ; font-size: calc(42.2px / 2); font-weight: 600; line-height: calc(55px / 2);  
}
.mobile-fee-low-inner {
    position: relative; margin-left: 20px; width: calc(100% - 40px); float: left; border-radius: calc(37.98px / 2); height: calc(379.8px / 2); background: white
}
.mobile-fee-low-icon {
    width: calc(182px / 2); height: calc(182px / 2); position: absolute; right: calc(50px / 2); bottom: calc(50px / 2)
}
.main-fee-low-inner {
    position: relative; width: calc(50% - 18px); border-radius: 22.447px; float: left; height: 534px; background-color: white;
}
</style>

<script>
import Localization from '@/components/localization.js'
import { vueDetectMobile } from '@/components/VueDetectMobile.js'

export default {
    name: 'MainAbroadPaymentsComponent',
    components: { 
    },
    computed: {
        _getBG: function() {
            return `url('/img/any-currency-mobile.png')`
        }
    },
    data() {
        return {
            _mobile: false
        }
    },
    async mounted() {    
        this._mobile = vueDetectMobile()
    },
    methods: {
        getLocale(id) {
            return Localization.getLocaleString(id)       
        },
    }
}
</script>