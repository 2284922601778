<template>
    <!-- start mozaic -->
    <div class="main-mozaic-container" :style="scaleIcon">        
        <div class="main-mazaic-common-image main-mozaic-img-moz-1" style="background-image: url(/img/mozaic/moz_1.png)"></div>

        <div class="main-mazaic-common-image main-mozaic-img-moz-2" style="background-image: url(/img/mozaic/moz_2.png)"></div>

        <div class="main-mazaic-common-image main-mozaic-img-moz-3" style="background-image: url(/img/mozaic/moz_3.png)"></div>

        <div class="main-mazaic-common-image main-mozaic-img-moz-5" style="background-image: url(/img/mozaic/moz_4.png)"></div>

        <div class="main-mazaic-common-image main-mozaic-img-moz-4" style="background-image: url(/img/mozaic/moz_5.png)"></div>

        <div class="main-mazaic-common-image main-mozaic-img-moz-6" style="background-image: url(/img/mozaic/moz_6.png)"></div>

        <div class="main-mazaic-common-image main-mozaic-img-moz-7" style="background-image: url(/img/mozaic/moz_7.png)"></div>

        <div class="main-mazaic-common-image main-mozaic-img-moz-21" style="background-image: url(/img/mozaic/moz_21.png)"></div>

        <div class="main-mazaic-common-image main-mozaic-img-moz-22" style="background-image: url(/img/mozaic/moz_22.png)"></div>

        <div class="main-mazaic-common-image main-mozaic-img-moz-23" style="background-image: url(/img/mozaic/moz_23.png)"></div>

        <div class="main-mazaic-common-image main-mozaic-img-moz-24" style="background-image: url(/img/mozaic/moz_24.png)"></div>

        <div class="main-mazaic-common-image main-mozaic-img-moz-25" style="background-image: url(/img/mozaic/moz_25.png)"></div>

        <div class="main-mazaic-common-image main-mozaic-img-moz-26" style="background-image: url(/img/mozaic/moz_26.png)"></div>

        <div class="main-mazaic-common-image main-mozaic-img-moz-27" style="background-image: url(/img/mozaic/moz_27.png)"></div>
    </div>

    <!-- end mozaic -->
</template>

<style scoped>
.main-mozaic-img-moz-27 {
    width: 148.481px; height: 140.928px; top: calc(562px + 100px + 68px); right: calc(-295px - 148.481px)
}
.main-mozaic-img-moz-26 {
    width: 118.785px; height: 112.743px; top: calc(486px + 100px + 68px); right: calc(-107px - 118.785px)
}
.main-mozaic-img-moz-25 {
    width: 118.785px; height: 112.743px; top: calc(281px + 100px + 68px); right: calc(-92px - 118.785px)
}
.main-mozaic-img-moz-24 {
    width: 92.652px; height: 87.939px; top: calc(234px + 100px + 68px); right: calc(-151px - 92.652px)
}
.main-mozaic-img-moz-23 {
    width: 239.549px; height: 227.364px; top: calc(108px + 100px + 68px); right: calc(-311px - 239.549px)
}
.main-mozaic-img-moz-22 {
    width: 76.418px; height: 72.531px; top: calc(50px + 68px); right: calc(-234px - 76.418px)
}
.main-mozaic-img-moz-21 {
    width: 118.785px; height: 112.743px; top: calc(48px + 100px + 68px); right: calc(-71px - 118.785px)
}
.main-mozaic-img-moz-7 {
    width: 116.805px; height: 110.864px; top: calc(568px + 100px + 68px); left: calc(-63px - 116.805px)
}
.main-mozaic-img-moz-6 {
    width: 239.549px; height: 227.364px; top: calc(476px + 100px + 68px); left: calc(-310px - 239.549px)
}
.main-mozaic-img-moz-5 {
    width: 93.84px; height: 89.067px; top: calc(301px + 100px + 68px); left: calc(-89px - 93.84px)
}
.main-mozaic-img-moz-4 {
    width: 118.785px; height: 112.743px; top: calc(334px + 100px + 68px); left: calc(-118.785px - 124px)
}
.main-mozaic-img-moz-3 {
    width: 118.785px; height: 112.743px; top: calc(228px + 100px + 68px); left: calc(-257px - 220px)
}
.main-mozaic-img-moz-2 {
    top: calc(108px + 100px + 68px); left: calc(-68px - 109.678px); width: 109.678px;height: 104.099px;
}
.main-mozaic-img-moz-1 {
    width: 142.146px; height: 134.915px; position: absolute; top: calc(50px + 68px); left: calc(-288px - 142.146px)
}
.main-mazaic-common-image {
    position: absolute; background-repeat: no-repeat; background-position: center; background-size: 100% 100%;
}
.main-mozaic-container {
    width: 100%; height: 100%
}
@keyframes fadeInFromNone {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0.8;
    }
}
</style>

<script>
export default {
    name: 'MainMozaicComponent',
    components: { 
    },
    data() {
        return {
            _scroll: 0,
            _scale: 1,
            _opacity: 1
        }
    },
    methods: {
        handleScroll(event) { 
            console.log("*** handleScroll ")

            // will-change: transform, opacity; transform: scale(1.00729); opacity: 1;
            
            
            this._scroll = window.scrollY; 

            if(this._scroll <= screen.height) {
                var diff = this._scroll - screen.height
                var ratio = (diff / screen.height) + 2

                this._scale = ratio
            }
            
        }
    },
    computed: {
        scaleIcon() {
            return {           
                transform: `scale(${this._scale})`
            }
        }
    },
    mounted() {
        console.log("*** mounted ")
        window.addEventListener('scroll', this.handleScroll);

        // window.addEventListener('scroll', (event) => {
        //     console.log("*** scroll")

        // });
    },
    created() {
        console.log("*** created ")
        // window.addEventListener('scroll', this.handleScroll);
    },
    destroyed () {
        // window.removeEventListener('scroll', this.handleScroll);
    },
    watch: {            

    } 
}
</script>

