<template>
    <div style="width: 100%; float: left; margin-top: 34px;">
        <div style="position: relative; width: 610px; border-radius: 22.447px; float: left; height: 534px; background-color: white;">          
            <div style="width: 100%; height: 100%; background-image: url('/img/calc.png'); background-repeat: no-repeat; background-position: bottom 48px right 41px; background-size: auto 65%; ">
                <div style="word-wrap: break-word; white-space: initial; margin-right: 52px; margin-left: 52px; margin-top: 49px; color: #191B1E; font-size: 37.411px; font-style: normal;  letter-spacing: 0.2px;">
                    <div style="font-weight: 600;"> {{ getLocale('menu_calc') }}</div>                      
                </div>           
            </div>
        
        </div>

        <div style="position: relative; width: 334px; border-radius: 22.447px; float: left; height: 534px; margin-left: 36px; background: linear-gradient(315deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0.00) 100%), #DEE3EC; background-blend-mode: soft-light, normal;">          
            <div style="color: #191B1E; word-wrap: break-word; white-space: initial; margin-right: 52px; margin-left: 52px; margin-top: 49px; color: #191B1E; font-size: 37.411px; font-style: normal; font-weight: 600; line-height: 44.893px;  letter-spacing: 0.2px;">
                FAQ
            </div>

            <div style="position: absolute; height: 34px; width: 62px; left: 51px; bottom: 48px;">
                <img src="/img/any-questions.png" style="width: 100%; "/>
            </div>
        </div>
    </div>
</template>

<script>
import Localization from '@/components/localization.js'
import { vueDetectMobile } from '@/components/VueDetectMobile.js'

export default {
    name: 'MainCalculationComponent',
    components: { 
    },
    data() {
        return {
            _mobile: false
        }
    },
    async mounted() {    
        this._mobile = vueDetectMobile()
    },
    methods: {
        getLocale(id) {
            return Localization.getLocaleString(id)       
        },
    }
}
</script>