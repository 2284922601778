<template>
    <div v-if="_mobile" style="width: 100%; float: left; margin-top: calc(90px / 2);">
        <div style="width: 100%; float: left; text-align: center;">
            <span style="color: #191B1E;  font-size: calc(67.52px / 2); font-style: normal; font-weight: 700;">Hazeena</span>
        </div>
        <div style="width: 100%; float: left; text-align: center;margin-top: 15px;">
            <span style="color: #191B1E; font-size: calc(37.98px / 2); font-style: normal; font-weight: 600;">Современный банковский сервис</span>
        </div>
        <div style="width: 100%; float: left; text-align: center; margin-top: 10px;">
            <span style="color: #191B1E; font-size: calc(37.98px / 2); font-style: normal; font-weight: 600;">для тех, кто хочет:</span>
        </div>
    </div>


    <div class="main-chapter" v-if="!_mobile" id="hazeena_chapter">
        <div class="main-chapter-title">
            Hazeena
        </div>
        <div class="main-hawala-title main-hawala-title-1">{{ getLocale('main_hawala_title_1') }}</div>
        <div class="main-hawala-title main-hawala-title-2">{{ getLocale('main_hawala_title_2') }}</div>
    </div>
</template>

<style scoped>
.main-hawala-title-1 {
    line-height: 63.848px
}
.main-hawala-title-2 {
    line-height: 52.375px;
}
.main-hawala-title {
    color: #191B1E; font-size: 37.411px; font-style: normal; font-weight: 600; ; letter-spacing: 0.2px;
}
</style>

<script>
// @ is an alias to /src
import Localization from '@/components/localization.js'
import { vueDetectMobile } from '@/components/VueDetectMobile.js'

export default {
  name: 'HomeView',
  components: {
    
  },
  methods: {
    getLocale(id) {
        return Localization.getLocaleString(id)       
    },
  },
  data() {
    return {
      _mobile: false
    }
  },
  async mounted() {    
    this._mobile = vueDetectMobile()
  }
}
</script>