<template>
    <div v-if="_mobile" style="width: 100%; float: left; margin-top: calc(33px / 2) !important;">
        <div style="background: white; position: relative;  margin-left: 20px;  width: calc(100% - 40px); float: left; border-radius: calc(37.98px / 2); height: calc(168.8px / 2);">
            <div class="mobile-app-download-icon">
                <img src="/img/app-icon.png" style="width: 100%"/>
            </div>

            <div style="float: left; height: 100%; margin-left: calc(25px / 2);">
                <div style="margin-top: 22px; color: #191B1E; font-size: calc(29.54px / 2); font-style: normal; font-weight: 600; letter-spacing: 0.2px;">Скачайте Hazeena</div>
                <div style="margin-top: 5px; color: #191B1E; font-size: calc(26.1px / 2); font-style: normal; font-weight: 400;  letter-spacing: 0.2px;">на русском языке</div>
            </div>

            <div class="mobile-app-download-apple">
                <img src="/img/mobile-apple-store.png" style="width: 100%"/>
            </div>
            <div class="mobile-app-download-google">
                <img src="/img/mobile-gogle-store.png" style="width: 100%"/>
            </div>
        </div>
    </div>

    <div v-if="!_mobile" style="width: 100%; float: left; margin-top: 34px; height: 146px;">
        <div style="width: 100%; height: 100%; border-radius: 22.447px; background: white">
            <div style="margin-left: 51px; float: left; width: 81.921px; position: relative; top: 50%; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%); transform: translateY(-50%);">
                <img src="/img/app-icon.png" style="width: 100%"/>
            </div>
            <div style="float: left; height: 100%; margin-left: 47px;">
                <div style="margin-top:22px; color: #191B1E; font-size: 34.917px; font-style: normal; font-weight: 600; letter-spacing: 0.2px;">{{ getLocale('home_ordering_title_1') }}</div>
                <div style="color: #191B1E; font-size: 27.934px; font-style: normal; font-weight: 400;  letter-spacing: 0.2px;">{{ getLocale('home_app_download') }}</div>
            </div>

            <div style="margin-right: 51px; width: 140px; float: right;position: relative; top: 50%; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%); transform: translateY(-50%);">
                <img src="/img/googleplay.png" style="width: 100%"/>
            </div>
            <div style="margin-right: 20px; width: 140px; float: right;position: relative; top: 50%; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%); transform: translateY(-50%);">
                <img src="/img/appstore.png" style="width: 100%"/>
            </div>
            
        </div>
    </div>
</template>

<style scoped>
.mobile-app-download-google {
    margin-left: 15px; width: calc(60px / 2); float: left; position: relative; top: 50%; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%); transform: translateY(-50%);
}
.mobile-app-download-apple {
    margin-left: 15px; width: calc(60px / 2); float: left; position: relative; top: 50%; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%); transform: translateY(-50%);
}
.mobile-app-download-icon {
    margin-left: calc(49px / 2); float: left; width: calc(93px / 2); position: relative; top: 50%; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%); transform: translateY(-50%);
}
@media only screen and (max-width: 360px) {
    .mobile-app-download-icon {
        margin-left: calc(49px / 2.3); float: left; width: calc(93px / 2.3); position: relative; top: 50%; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%); transform: translateY(-50%);
    }
    .mobile-app-download-google {
        margin-left: 15px; width: calc(60px / 3); float: left; position: relative; top: 50%; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%); transform: translateY(-50%);
    }
    .mobile-app-download-apple {
        margin-left: 15px; width: calc(60px / 3); float: left; position: relative; top: 50%; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%); transform: translateY(-50%);
    }
}
</style>

<script>
import Localization from '@/components/localization.js'
// @ is an alias to /src
import { vueDetectMobile } from '@/components/VueDetectMobile.js'

export default {
  name: 'HomeView',
  components: {
    
  },
  data() {
    return {
      _mobile: false
    }
  },
  async mounted() {    
    this._mobile = vueDetectMobile()
  },  
  methods: {
    getLocale(id) {
        return Localization.getLocaleString(id)       
    },
  }
}
</script>