<template>
    <header>
      <div id="mobile-header" v-if="_mobile" style="width: 100%; height: calc(118px / 2); float: left; text-align: left; background-color: #E4E6E8;">
          <div style="height: calc(118px / 2); margin-left: calc(42px / 2); float: left; margin-top: 18px;">
              <a href="/">
                  <img src="/img/logo.png" style="height: calc(45px / 2);"/>
              </a>
          </div>
          <div style="float: right;"></div>
        </div>


      <div class="header-container" v-if="!_mobile">
        <MainMenuComponent :selected="selected"/>
      </div>
    </header>
    <main>
        <slot></slot>
    </main>
    <footer>
        <div class="footer-container">
          <div style="float: left;background: #191B1E; width: 100%; min-height: 200px;" v-if="_mobile">
              <div class="mobile-footer-inner">
                  <div style="height: 17px;">
                    <svg xmlns="http://www.w3.org/2000/svg" height="17" viewBox="0 0 233 41" fill="none">
                  <path d="M47.3403 7.20555C42.8939 2.76439 36.9805 0.317225 30.689 0.317225C24.3975 0.317225 18.9681 2.58312 14.5822 6.69194L7.89746 0L7.36813 0.528709C2.92173 4.96986 0.47168 10.8763 0.47168 17.1604C0.47168 23.4445 2.92173 29.3509 7.36813 33.7921C11.8145 38.2332 17.7279 40.6804 24.0194 40.6804C30.3109 40.6804 35.7404 38.4145 40.1263 34.3057L13.5084 7.73426L46.811 40.9976L47.3403 40.4689C56.5205 31.2996 56.5205 16.3749 47.3403 7.20555ZM39.0525 33.2633C34.9539 37.0852 29.6455 39.1849 24.0194 39.1849C18.3934 39.1849 12.601 36.8888 8.44192 32.7346C4.28288 28.5654 1.98406 23.0366 1.98406 17.1604C1.98406 11.2842 4.08627 6.23877 7.91259 2.14505L39.0525 33.2482V33.2633Z" fill="#999A9B"/>
                  <path d="M78.0113 13.733L84.8019 11.2405C89.7625 11.2405 91.5925 13.2798 91.5925 18.7935V26.3465H93.9064V30.0777H83.108V26.3465H85.422V20.863C85.422 16.6183 84.4994 15.3494 82.0343 15.3494L78.0264 16.5276V26.3616H80.3404V30.0928H69.542V26.3616H71.8559V10.3191H69.542V6.58789H78.0264V13.733H78.0113Z" fill="#999A9B"/>
                  <path d="M114.928 26.3311H117.242V30.0623H111.269L109.998 27.8719L102.557 30.1831C98.1713 30.1831 95.7969 26.89 95.7969 20.7872C95.7969 14.6844 98.1713 11.2402 102.557 11.2402L108.758 12.781L110.301 11.2402H114.928V26.3311ZM105.053 15.5756C102.95 15.5756 101.816 16.8748 101.816 20.7872C101.816 24.6997 102.95 26.3915 105.053 26.3915L108.758 25.7722V16.195L105.053 15.5756Z" fill="#999A9B"/>
                  <path d="M193.119 13.7025L200.862 11.2402C205.823 11.2402 207.653 13.2795 207.653 18.7932V26.3462H209.967V30.0774H199.169V26.3462H201.483V20.8627C201.483 16.618 200.56 15.0469 198.095 15.0469L194.087 16.2252V26.3613H196.401V30.0925H185.603V26.3613H187.916V15.2735H185.603V11.2704H191.622L193.134 13.7327L193.119 13.7025Z" fill="#999A9B"/>
                  <path d="M230.686 26.3311H233V30.0623H227.026L225.756 27.8719L218.315 30.1831C213.929 30.1831 211.555 26.89 211.555 20.7872C211.555 14.6844 213.929 11.2402 218.315 11.2402L224.516 12.781L226.058 11.2402H230.686V26.3311ZM220.81 15.5756C218.708 15.5756 217.574 16.8748 217.574 20.7872C217.574 24.6997 218.708 26.3915 220.81 26.3915L224.516 25.7722V16.195L220.81 15.5756Z" fill="#999A9B"/>
                  <path d="M130.007 26.2405C129.236 26.2405 128.464 26.18 127.693 26.0894L127.224 26.029L138.144 16.3612L138.476 11.2402H120.645L119.919 18.3249H123.655C124.109 15.3642 125.787 15.2433 130.446 15.2433H131.277L119.284 25.4398V30.0623H138.779V22.1467H135.814C135.331 25.3643 132.759 26.2405 129.992 26.2405H130.007Z" fill="#999A9B"/>
                  <path d="M157.91 23.7787C156.897 25.1987 155.233 26.6488 152.722 26.6488C149.804 26.6488 148.125 24.8965 147.853 21.7243H160.708C160.859 20.9539 160.95 20.0626 160.95 19.3224C160.95 14.1109 157.123 11.2559 151.785 11.2559C145.191 11.2559 141.606 14.5792 141.606 20.8028C141.606 27.0265 145.282 30.3498 152.087 30.3498C156.216 30.3498 159.241 28.3105 161.101 25.667L157.895 23.7938L157.91 23.7787ZM151.8 14.6245C153.494 14.6245 155.036 15.0324 155.036 17.6457C155.036 17.8572 155.036 18.0838 155.006 18.3255H148.034C148.533 15.984 149.819 14.6245 151.8 14.6245Z" fill="#999A9B"/>
                  <path d="M180.036 23.7787C179.023 25.1987 177.359 26.6488 174.848 26.6488C171.93 26.6488 170.251 24.8965 169.979 21.7243H182.834C182.985 20.9539 183.076 20.0626 183.076 19.3224C183.076 14.1109 179.249 11.2559 173.911 11.2559C167.317 11.2559 163.732 14.5792 163.732 20.8028C163.732 27.0265 167.408 30.3498 174.213 30.3498C178.342 30.3498 181.367 28.3105 183.227 25.667L180.021 23.7938L180.036 23.7787ZM173.926 14.6245C175.62 14.6245 177.162 15.0324 177.162 17.6457C177.162 17.8572 177.162 18.0838 177.132 18.3255H170.16C170.659 15.984 171.945 14.6245 173.926 14.6245Z" fill="#999A9B"/>
                  </svg>
                  </div>
              </div>
              <div style="width: 100%; height: 80px; margin-left: 20px">
                  <div style="margin-top: 15px; color: #999A9B; font-size: calc(20.885px / 2); font-style: normal; font-weight: 400; line-height: calc(80px / 2);  letter-spacing: 0.2px;">
                      Copyright ©2023 Hazeena Pay. All rights reserved.
                  </div>
              </div>
          </div>

            <div class="hw-container" v-if="!_mobile">
              <div style="width: 100%; float: left; margin-top: 30px;">

                <div style="width: 20%; float: left">
                  <p style="color: #FFFFFF; font-size: 15px; font-style: normal; font-weight: 600;">Essentials</p>
                  <p class="footer-menu-item">
                    <a href="/#hazeena_chapter">Description</a>
                  </p>
                  <p class="footer-menu-item"><a href="/#transfers_chapter">Transfers</a></p>
                  <p class="footer-menu-item"><a href="/#calc_chapter">Calc</a></p>
                  <p class="footer-menu-item"><a href="/company">About</a></p>
                  <p></p>
                </div>

                <div style="width: 20%; float: left">
                  <p style="color: #FFFFFF; font-size: 15px; font-style: normal; font-weight: 600;">Docs</p>
                  <p class="footer-menu-item"><a href="/docs/data_protection">Personal Data Protection Guidelines</a></p>
                  <p class="footer-menu-item"><a href="/docs/privacy">Privacy policy</a></p>
                  <p class="footer-menu-item"><a href="/docs/terms">Public offer</a></p>
                </div>

              </div>

                <div class="footer-inner">
                    <div style="height: 29px;">
                      <svg xmlns="http://www.w3.org/2000/svg"  height="29" viewBox="0 0 233 41" fill="none">
                      <path d="M47.3403 7.20555C42.8939 2.76439 36.9805 0.317225 30.689 0.317225C24.3975 0.317225 18.9681 2.58312 14.5822 6.69194L7.89746 0L7.36813 0.528709C2.92173 4.96986 0.47168 10.8763 0.47168 17.1604C0.47168 23.4445 2.92173 29.3509 7.36813 33.7921C11.8145 38.2332 17.7279 40.6804 24.0194 40.6804C30.3109 40.6804 35.7404 38.4145 40.1263 34.3057L13.5084 7.73426L46.811 40.9976L47.3403 40.4689C56.5205 31.2996 56.5205 16.3749 47.3403 7.20555ZM39.0525 33.2633C34.9539 37.0852 29.6455 39.1849 24.0194 39.1849C18.3934 39.1849 12.601 36.8888 8.44192 32.7346C4.28288 28.5654 1.98406 23.0366 1.98406 17.1604C1.98406 11.2842 4.08627 6.23877 7.91259 2.14505L39.0525 33.2482V33.2633Z" fill="#999A9B"/>
                      <path d="M78.0113 13.733L84.8019 11.2405C89.7625 11.2405 91.5925 13.2798 91.5925 18.7935V26.3465H93.9064V30.0777H83.108V26.3465H85.422V20.863C85.422 16.6183 84.4994 15.3494 82.0343 15.3494L78.0264 16.5276V26.3616H80.3404V30.0928H69.542V26.3616H71.8559V10.3191H69.542V6.58789H78.0264V13.733H78.0113Z" fill="#999A9B"/>
                      <path d="M114.928 26.3311H117.242V30.0623H111.269L109.998 27.8719L102.557 30.1831C98.1713 30.1831 95.7969 26.89 95.7969 20.7872C95.7969 14.6844 98.1713 11.2402 102.557 11.2402L108.758 12.781L110.301 11.2402H114.928V26.3311ZM105.053 15.5756C102.95 15.5756 101.816 16.8748 101.816 20.7872C101.816 24.6997 102.95 26.3915 105.053 26.3915L108.758 25.7722V16.195L105.053 15.5756Z" fill="#999A9B"/>
                      <path d="M193.119 13.7025L200.862 11.2402C205.823 11.2402 207.653 13.2795 207.653 18.7932V26.3462H209.967V30.0774H199.169V26.3462H201.483V20.8627C201.483 16.618 200.56 15.0469 198.095 15.0469L194.087 16.2252V26.3613H196.401V30.0925H185.603V26.3613H187.916V15.2735H185.603V11.2704H191.622L193.134 13.7327L193.119 13.7025Z" fill="#999A9B"/>
                      <path d="M230.686 26.3311H233V30.0623H227.026L225.756 27.8719L218.315 30.1831C213.929 30.1831 211.555 26.89 211.555 20.7872C211.555 14.6844 213.929 11.2402 218.315 11.2402L224.516 12.781L226.058 11.2402H230.686V26.3311ZM220.81 15.5756C218.708 15.5756 217.574 16.8748 217.574 20.7872C217.574 24.6997 218.708 26.3915 220.81 26.3915L224.516 25.7722V16.195L220.81 15.5756Z" fill="#999A9B"/>
                      <path d="M130.007 26.2405C129.236 26.2405 128.464 26.18 127.693 26.0894L127.224 26.029L138.144 16.3612L138.476 11.2402H120.645L119.919 18.3249H123.655C124.109 15.3642 125.787 15.2433 130.446 15.2433H131.277L119.284 25.4398V30.0623H138.779V22.1467H135.814C135.331 25.3643 132.759 26.2405 129.992 26.2405H130.007Z" fill="#999A9B"/>
                      <path d="M157.91 23.7787C156.897 25.1987 155.233 26.6488 152.722 26.6488C149.804 26.6488 148.125 24.8965 147.853 21.7243H160.708C160.859 20.9539 160.95 20.0626 160.95 19.3224C160.95 14.1109 157.123 11.2559 151.785 11.2559C145.191 11.2559 141.606 14.5792 141.606 20.8028C141.606 27.0265 145.282 30.3498 152.087 30.3498C156.216 30.3498 159.241 28.3105 161.101 25.667L157.895 23.7938L157.91 23.7787ZM151.8 14.6245C153.494 14.6245 155.036 15.0324 155.036 17.6457C155.036 17.8572 155.036 18.0838 155.006 18.3255H148.034C148.533 15.984 149.819 14.6245 151.8 14.6245Z" fill="#999A9B"/>
                      <path d="M180.036 23.7787C179.023 25.1987 177.359 26.6488 174.848 26.6488C171.93 26.6488 170.251 24.8965 169.979 21.7243H182.834C182.985 20.9539 183.076 20.0626 183.076 19.3224C183.076 14.1109 179.249 11.2559 173.911 11.2559C167.317 11.2559 163.732 14.5792 163.732 20.8028C163.732 27.0265 167.408 30.3498 174.213 30.3498C178.342 30.3498 181.367 28.3105 183.227 25.667L180.021 23.7938L180.036 23.7787ZM173.926 14.6245C175.62 14.6245 177.162 15.0324 177.162 17.6457C177.162 17.8572 177.162 18.0838 177.132 18.3255H170.16C170.659 15.984 171.945 14.6245 173.926 14.6245Z" fill="#999A9B"/>
                      </svg>
                    </div>
                </div>
                <div style="width: 100%; height: 80px; float: left;">
                    <div style="margin-top: 25px; color: #999A9B; font-size: 17.458px; font-style: normal; font-weight: 400; line-height: 32.423px;  letter-spacing: 0.2px;">
                        Copyright ©2023 Hazeena Pay. All rights reserved.
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<style scoped>
.footer-menu-item {
  color: #999A9B; font-size: 15px; font-style: normal; font-weight: 600;
}
.footer-menu-item a {
  color: #999A9B; font-size: 15px; font-style: normal; font-weight: 600;
}
.mobile-footer-inner {
  width: calc(100% - 40px); border-bottom: 1px solid #999A9B; height: calc(29px + 25px ); margin-top: 15px; margin-left: 20px; line-height: calc(29px + 25px )
}
.footer-inner {
  width: 100%; border-bottom: 1px solid #999A9B;height: calc(29px + 25px ); margin-top: 75px; float: left
}
.footer-container {
  width: 100%;  float: left; background-color: #191B1E;margin-top: 300px; text-align: left;
}
.header-container {
  width: 100%; float: left; background-color: #E4E6E8; height: 68px;
}
header {
  height: 68px;
}
</style>

<script>
// @ is an alias to /src
import { vueDetectMobile } from '@/components/VueDetectMobile.js'
import MainMenuComponent from '@/components/global/MainMenuComponent.vue'

export default {
  name: 'HomeView',
  components: {
    MainMenuComponent
  },
  props: {
      selected: String
  },
  data() {
    return {
      _mobile: false,
      _factor: 2.5
    }
  },
  async mounted() {    
    this._mobile = vueDetectMobile()
  }
}
</script>