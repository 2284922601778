<template>
    <div class="hw-container">
        <div class="main-menu-logo">
            <a href="/">
                <img src="/img/logo.png"/>
            </a>
        </div>
        <div class="main-menu-items">
            <div>
                <a href="/#hazeena_chapter">{{ getLocale("menu_desc") }}</a>
            </div>
            <!-- <div>
                <a href="/#cards_chapter">{{ getLocale("menu_cards") }}</a>
            </div> -->
            <div>
                <a href="/#transfers_chapter">{{ getLocale("menu_transfers") }}</a>
            </div>
            <div>
                <a href="/#calc_chapter">{{ getLocale("menu_calc") }}</a>
            </div>
            <!-- <div>
                <a href="/#crypto_chapter">Криптовалюта</a>
            </div> -->
            <div :class="{ 'main-menu-items-selected': selected == 'company' }">
                <a href="/company">{{ getLocale("menu_about") }}</a>
            </div>
        </div>
        <div class="main-menu-services">
            <div class="main-menu-lang" v-on:click="switchLocale()">
                <span>{{ getCurrentLocale }}</span>
            </div>
            <!-- <div class="main-menu-download">
                <span>Скачать</span>
            </div> -->
        </div>
    </div>
</template>

<style scoped>
.main-menu-items-selected {
    background: white
}
.main-menu-items > div:hover {
    background: white; cursor: pointer;
}
.main-menu-items > div {
   float: left;  /* margin-right: 30px; */
   border-radius: 9.976px; height: 35px; text-align: center; line-height: 35px; padding-left: 15px; padding-right: 15px;
}
.main-menu-items {
    height: 68px; float: left; line-height: 68px; margin-left: 40px; color: #10253C; font-size: 14.964px; font-style: normal; font-weight: 400; letter-spacing: 0.2px;
    display: flex; align-items: center;
}
.main-menu-download span {
    font-size: 14.964px; font-style: normal; font-weight: 600; color: white;
}
.main-menu-download {
    border-radius: 9.976px; background: #7B5DED; width: 153.601px; height: 35px; text-align: center; line-height: 35px;
}
.main-menu-lang span {
    font-size: 14.964px; font-style: normal; font-weight: 600; letter-spacing: 0.2px; color: #10253C; cursor: pointer;
}
.main-menu-lang {
    width: 62.977px; height: 34.017px; border-radius: 9.976px; border: 1px solid #BABABA; margin-right: 35px; line-height: 34.017px; text-align: center; 
}
.main-menu-services {
    float: right;  line-height: 68px; height: 68px; display: flex; align-items: center;
}
.main-menu-logo img {
    margin-top: 20px;
}
.main-menu-logo {
    float: left; line-height: 68px;
}
.main-menu-logo img {
    height: 26px
}
</style>

<script>
import Localization from '@/components/localization.js'

export default {
    name: 'MainMenuComponent',
    props: {
        selected: String
    },
    async mounted() {    
        console.log("*** MainMenuComponent " + this.selected)
    },
    computed: {
        getCurrentLocale() {
            var locale = 'ru';  
            if (localStorage) {
                var default_locale = localStorage.getItem('default_locale') 
                
                if(default_locale) {
                    locale = default_locale
                }
            }

            return locale.toUpperCase()
        },
    },
    methods: {
        switchLocale() {
            
            var currentLocale = this.getCurrentLocale.toLowerCase()

            console.log('*** switchLocale ' + currentLocale)

            var nextLocale = ''
            if(currentLocale == 'ru') {
                nextLocale = 'uz'
            }
            if(currentLocale == 'uz') {
                nextLocale = 'en'
            }
            if(currentLocale == 'en') {
                nextLocale = 'ru'
            }

            if (localStorage) {
                localStorage.setItem("default_locale", nextLocale)
            }

            window.location.reload();
        },
        getLocale(id) {
            return Localization.getLocaleString(id)       
        },
        scrollToElement(id) {
            this.$nextTick(function() {
                let element = document.getElementById(id);
                element.scrollIntoView({behavior: "smooth", block: "end"});
            });


        }
    }
}
</script>