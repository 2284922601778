<template>
    <div v-if="_mobile" style="width: 100%; float: left;">
        <div class="mobile-abroad-inner">
            <div class="mobile-abroad-title">
                Расплачиваться картой<br/>
                за границей<br/>
                <span style="color: #7B5DED;">без ограничений</span>
            </div>
        </div>
    </div>

    <div v-if="!_mobile" class="main-abroad-outer">
        <div class="main-abroad-inner">
            <div class="main-abroad-titles">            
                <div class="main-abroad-title">{{ getLocale('main_abroad_payments_title_1') }}</div>
                <div class="main-abroad-title">{{ getLocale('main_abroad_payments_title_2') }}</div>
                <div class="main-abroad-title main-abroad-title-blue">{{ getLocale('main_abroad_payments_title_3') }}</div>
            </div>          
        </div>
    </div>
</template>

<style scoped>
.mobile-abroad-title {
    word-wrap: break-word; 
    white-space: initial; 
    margin-right: calc(52px / 2); 
    margin-left: calc(52px / 2); 
    margin-top: calc(52px / 2) ;
    font-size: calc(42.2px / 2);
    font-weight: 600; 
    line-height: calc(55px / 2);  
    letter-spacing: 0.2px;
}
.mobile-abroad-inner {
    margin-top: calc(33px / 2);
    position: relative; 
    margin-left: 20px; 
    width: calc(100% - 40px); 
    float: left; 
    border-radius: calc(37.98px / 2); 
    height: calc(900px / 2); 
    background: white;    
    background-image: v-bind(_getBG); 
    background-repeat: no-repeat; background-position: bottom 0% right 0%; background-size: auto calc(779.012px / 2.5);
}
@media only screen and (max-width: 360px) {
    .mobile-abroad-inner {
    margin-top: calc(33px / 2);
    position: relative; 
    margin-left: 20px; 
    width: calc(100% - 40px); 
    float: left; 
    border-radius: calc(37.98px / 2); 
    height: calc(900px / 2); 
    background: white;    
    background-image: v-bind(_getBG); 
    background-repeat: no-repeat; background-position: bottom 0% right 0%; background-size: auto calc(700px / 2.5);
}
}
.main-abroad-title-blue {
    color: #7B5DED;
}
.main-abroad-title {
    line-height: 44.893px;height: 44.893px;
}
.main-abroad-titles {
    word-wrap: break-word; white-space: initial; margin-right: 52px; margin-left: 52px; padding-top: 49px; color: #191B1E; font-size: 37.411px; font-style: normal; font-weight: 600; line-height: 44.893px;  letter-spacing: 0.2px;
}
.main-abroad-outer {
    width: 100%; float: left; margin-top: 34px; height: 534px;
}
.main-abroad-inner {
    width: 100%; height: 100%; border-radius: 22.447px; background: white; position: relative; 
    background-image: v-bind(_getBG); 
    background-repeat: no-repeat; background-position: top 50% right 0%; background-size: auto 90%;
}
</style>

<script>
import Localization from '@/components/localization.js'
import { vueDetectMobile } from '@/components/VueDetectMobile.js'

export default {
    name: 'MainAbroadPaymentsComponent',
    components: { 
    },
    computed: {
        _getBG: function() {
            return `url("/img/block_3_img.png")`
        }
    },
    methods: {
        getLocale(id) {
            return Localization.getLocaleString(id)       
        },
    },
    data() {
        return {
            _mobile: false
        }
    },
    async mounted() {    
        this._mobile = vueDetectMobile()
    }
}
</script>