<template>
    <div v-if="_mobile" style="width: 100%; float: left; margin-top: 20px">
        <div class="mobile-flash-topup-inner">
            <div class="mobile-flash-topup-title">
                <div style="font-weight: 600; color: white">Мгновенное<br/>зачисление</div> 
                <div style="margin-top:5px; line-height: 20px; color: white; font-size: calc(29.54px / 2);font-weight: 400; ">Перевод доступен<br/>сразу после отправки</div>            
            </div>
            <div class="mobile-flash-topup-icon" >
                <img src="/img/flash-topup.png" style="width: 100%"/>
            </div>
        </div>
    </div>

    <div v-if="!_mobile" class="main-flash-topup-outer">
        <div class="main-flash-topup-inner">                    
            <div style="word-wrap: break-word; white-space: initial; font-size: 37.411px; font-style: normal;  letter-spacing: 0.2px;">
                <div style="font-weight: 600; color: white">{{ getLocale('home_transfers_5') }}</div> 
                <div style="font-weight: 400; font-size: 27.934px; color: white">{{ getLocale('home_transfers_6') }}</div>            
            </div> 

            <div style="position: absolute;width: 300px; right: 71px; bottom: 48px;">
                <img src="/img/flash-topup.png" style="width: 100%; "/>
            </div>
        </div>
    </div>
</template>

<style scoped>
.mobile-flash-topup-title {
    margin-right: calc(52px / 2);  margin-left: calc(52px / 2); margin-top: calc(52px / 2) ; font-size: calc(42.2px / 2); font-weight: 600; line-height: calc(55px / 2);  
}
.mobile-flash-topup-inner {
    position: relative; margin-left: 20px; width: calc(100% - 40px); float: left; border-radius: calc(37.98px / 2); height: calc(379.8px / 2); background: linear-gradient(107deg, #EE6011 -0.73%, rgba(238, 96, 17, 0.00) 100%), #D71A1A;
}
.mobile-flash-topup-icon {
    width: calc(200px / 1.8); height: calc(293px / 1.8); position: absolute; right: calc(50px / 2); bottom: 0px
}
.main-flash-topup-inner {
    position: relative; padding-left: 51px; padding-top: 48px; width: calc(100% - 51px); height: calc(100% - 48px); border-radius: 22.447px; background: linear-gradient(135deg, #EE6011 0%, rgba(238, 96, 17, 0.00) 100%), #D71A1A;
}
.main-flash-topup-outer {
    width: 100%; float: left; margin-top: 75px; height: 534px;
}
</style>

<script>
import Localization from '@/components/localization.js'
import { vueDetectMobile } from '@/components/VueDetectMobile.js'

export default {
    name: 'MainAbroadPaymentsComponent',
    components: { 
    },
    data() {
        return {
            _mobile: false
        }
    },
    async mounted() {    
        this._mobile = vueDetectMobile()
    },
    methods: {
        getLocale(id) {
            return Localization.getLocaleString(id)       
        },
    }
}
</script>