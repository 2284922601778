<template>
    <div v-if="_mobile" style="width: 100%; float: left; margin-top: 20px">
        <div style="position: relative; margin-left: 20px; width: calc(100% - 40px); float: left; border-radius: calc(37.98px / 2); height: calc(379.8px / 2); background: linear-gradient(107deg, rgba(226, 77, 29, 0.60) 0%, rgba(226, 124, 29, 0.00) 100%), #F3E032;">
            <div style="color: white; margin-right: calc(52px / 2);  margin-left: calc(52px / 2); margin-top: calc(52px / 2) ; font-size: calc(42.2px / 2); font-weight: 600; line-height: calc(55px / 2);  ">
                <div style="font-weight: 600;">
                    Моментальный выпуск<br/>
                    без личного присутствия 
                </div>                 
            </div>
            <div style="width: calc(254px / 2); height: calc(133px / 2); position: absolute; right: calc(51px / 2); bottom: calc(48px / 2);">
                <img src="/img/instant-card.png" style="width: 100%"/>
            </div>
        </div>
    </div>

    <div v-if="!_mobile" class="main-instant-card-outer">
        <div class="main-instant-card-inner">          
            <div class="main-instant-card-title">
                Моментальный выпуск<br/>
                без личного присутствия        
            </div>    
            
            <div class="main-instant-card-img">
                <img src="/img/instant-card.png"/>
            </div>
        </div>
    </div>
</template>

<style scoped>
.main-instant-card-img img {
    width: 100%; filter: drop-shadow(0px 4.988136291503906px 7.981016635894775px rgba(0,0,0,0.25));
}
.main-instant-card-img {
    position: absolute;width: 470.98px; height: 234.626px; right: 51px; bottom: 48px;
}
.main-instant-card-title {
    color: #FFF; font-size: 37.411px; font-style: normal; font-weight: 600; line-height: 49.881px; letter-spacing: 0.2px;
}
.main-instant-card-inner {
    position: relative; padding-left: 51px; padding-top: 48px; width: calc(100% - 51px); height: calc(100% - 48px); border-radius: 22.447px; background: linear-gradient(135deg, rgba(226, 77, 29, 0.60) 0%, rgba(226, 124, 29, 0.00) 100%), #F3E032;
}
.main-instant-card-outer {
    width: 100%; float: left; margin-top: 75px; height: 534px;
}
</style>

<script>
import { vueDetectMobile } from '@/components/VueDetectMobile.js'

export default {
    name: 'MainAbroadPaymentsComponent',
    components: { 
    },
    data() {
        return {
            _mobile: false
        }
    },
    async mounted() {    
        this._mobile = vueDetectMobile()
    }
}
</script>