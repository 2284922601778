import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { VueShowdownPlugin } from 'vue-showdown';

var app = createApp(App)

app.directive('scroll', {
    inserted: function(el, binding) {
      let f = function(evt) {
        if (binding.value(evt, el)) {
          window.removeEventListener('scroll', f);
        }
      }
      window.addEventListener('scroll', f);
    }
  });

  app
    .use(store)
    .use(router)
    .use(VueShowdownPlugin, {
      options: {
        emoji: true,
        simpleLineBreaks: true
      },
    })
    .mount('#app')
