<template>
    <div v-if="_mobile" class="mobile-chapter">
        <div class="mobile-chapter-title">{{ title }}</div>
    </div>  
    <div v-if="!_mobile" class="main-chapter" :id="id">
        <div class="main-chapter-title">{{ title }}</div>
    </div>  
</template>

<script>
import { vueDetectMobile } from '@/components/VueDetectMobile.js'

export default {
  name: 'MainChapterComponent',
  props: {
    title: String,
    id: String
  },
  data() {
    return {
      _mobile: false
    }
  },
  async mounted() {    
    this._mobile = vueDetectMobile()
  }
}
</script>