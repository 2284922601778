<template>
    <div v-if="_mobile" style="width: 100%; float: left; margin-top: 34px;">
        <div class="mobile-card-usage-block" style="background: linear-gradient(322deg, #F23360 0%, rgba(192, 51, 242, 0.46) 54.17%, rgba(242, 51, 96, 0.00) 100%), #000;">
            <div class="mobile-card-usage-title">
                Начните<br/>
                пользоваться<br/> 
                виртуальной <br/>
                картой<br/>
                через 1 минуту
            </div>
            <div class="mobile-card-usage-icon">
                <img src="/img/block-usage-1.png" style="width: 100%;"/>
            </div>
        </div>

        <div class="mobile-card-usage-block" style="background: white; margin-top: calc(33px / 2) !important;">
            <div class="mobile-card-usage-title" style="color: #191B1E;">
                Ожидайте доставку<br/>
                физической карты<br/>
                через 7 дней
            </div>
            <div class="mobile-card-usage-icon">
                <img src="/img/block-usage-2.png" style="width: 100%;"/>
            </div>
        </div>
    </div>

    <div v-if="!_mobile" style="width: 100%; float: left; margin-top: 34px;">
        <div style="position: relative; width: calc(50% - 18px); border-radius: 22.447px; background: linear-gradient(315deg, #F23360 0%, rgba(192, 51, 242, 0.46) 54.17%, rgba(242, 51, 96, 0.00) 100%), #000; float: left; height: 534px">
            <div style="word-wrap: break-word; white-space: initial; margin-right: 52px; margin-left: 52px; margin-top: 49px; color: #FFF; font-size: 30px; font-style: normal; font-weight: 600; line-height: 44.893px;  letter-spacing: 0.2px;">
                Начните пользоваться виртуальной картой через 1 минуту
            </div>
            <div style="width: 173.978px; height: 262.42px; position: absolute; right: 51px; bottom: 48px;">
                <img src="/img/block-usage-1.png" style="width: 100%;"/>
            </div>
        </div>

        <div style="position: relative; width: calc(50% - 18px); border-radius: 22.447px; background: white; float: left; height: 534px; margin-left: 36px;">
            <div style="word-wrap: break-word; white-space: initial; margin-right: 52px; margin-left: 52px; margin-top: 49px; color: #191B1E; font-size: 30px; font-style: normal; font-weight: 600; line-height: 44.893px;  letter-spacing: 0.2px;">
                Ожидайте доставку физической карты через 7 дней
            </div>

            <div style="width: 173.978px; height: 262.42px; position: absolute; right: 51px; bottom: 48px;">
                <img src="/img/block-usage-2.png" style="width: 100%;"/>
            </div>
        </div>
    </div>
</template>

<style scoped>
.mobile-card-usage-icon {
    width: calc(146.055px / 2); height: calc(232.1px / 2); position: absolute; right: calc(51px / 2); bottom: calc(48px / 2); 
}

.mobile-card-usage-title {
    word-wrap: break-word; white-space: initial; margin-right: calc(52px / 2); margin-left: calc(52px / 2); margin-top: calc(49px / 2); color: #FFF; font-size: calc(42.2px / 2); font-style: normal; font-weight: 600; line-height: normal;  letter-spacing: 0.2px;
}
@media only screen and (max-width: 360px) {
    .mobile-card-usage-title {
        word-wrap: break-word; white-space: initial; margin-right: calc(52px / 2); margin-left: calc(52px / 2); margin-top: calc(49px / 2); color: #FFF; font-size: calc(42.2px / 2.5); font-style: normal; font-weight: 600; line-height: normal;  letter-spacing: 0.2px;
    }
}
.mobile-card-usage-block {
    margin-top: calc(24px); position: relative;  margin-left: 20px;  width: calc(100% - 40px); float: left; border-radius: calc(37.98px / 2); height: calc(379.8px / 2);
}
</style>

<script>
// @ is an alias to /src
import { vueDetectMobile } from '@/components/VueDetectMobile.js'

export default {
  name: 'HomeView',
  components: {
    
  },
  data() {
    return {
      _mobile: false
    }
  },
  async mounted() {    
    this._mobile = vueDetectMobile()
  }
}
</script>