<template>
    <div v-if="_mobile" style="width: 100%; float: left; margin-top: 20px">
        <div class="mobile-crypto-low-fee-linked-inner">
            <div class="mobile-crypto-low-fee-linked-title">
                <div style="font-weight: 600; color: #000; line-height: calc(44.893px / 2)">Привязка кошелька</div> 
                <div style="font-weight: 600; color: #9F3AEE">к приложению Hazeena</div>              
            </div>
            <div class="mobile-crypto-low-fee-linked-icon" >
                <img src="/img/link-wallet-mobile.png" style="width: 100%"/>
            </div>
        </div>

        <div class="mobile-crypto-low-fee-inner">          
            <div class="mobile-crypto-low-fee-title">
                Комиссия
            </div>

            <div class="mobile-crypto-low-fee-subtitle">
                1%
            </div>
        </div>
    </div>


    <div v-if="!_mobile" style="width: 100%; float: left; margin-top: 34px;">
        <div class="main-crypto-low-fee-inner">          
            <div class="main-crypto-low-fee-title">
                Комиссия
            </div>

            <div class="main-crypto-low-fee-subtitle">
                1%
            </div>
        </div>

        <div class="main-crypto-low-fee-linked-outer" style="">          
            <div class="main-crypto-low-fee-linked-inner" style="">
                <div class="main-crypto-low-fee-linked-titles" style="">
                    <div style="font-weight: 600; color: #000; line-height: 44.893px">Привязка кошелька</div> 
                    <div style="font-weight: 600; font-size: 37.411px; color: #9F3AEE">к приложению Hazeena</div>                    
                </div>           
            </div>        
        </div>        
    </div>
</template>

<style scoped>
.mobile-crypto-low-fee-inner {
    position: relative; margin-left: 20px; width: calc(100% - 40px); float: left; border-radius: calc(37.98px / 2); height: calc(379.8px / 4);
    background: linear-gradient(161deg, #6D23E5 0%, rgba(109, 35, 229, 0.00) 100%), #191B1E;
    margin-top: 20px;
}
.mobile-crypto-low-fee-title {
    float: left; height: 100%;
    color: #FFF;
    font-size: calc(42.2px / 2);
    font-style: normal;
    font-weight: 600;
    line-height: calc(379.8px / 4);
    letter-spacing: 0.338px;
    margin-left: 20px;
}
.mobile-crypto-low-fee-subtitle {
    float: right; height: 100%;
    color: #FFF;
    font-size: calc(93.147px / 2);
    font-style: normal;
    font-weight: 600;
    line-height: calc(379.8px / 4);
    letter-spacing: 0.244px;
    margin-right: 20px;
}

.mobile-crypto-low-fee-linked-title {
    margin-right: calc(52px / 2);  margin-left: calc(52px / 2); margin-top: calc(52px / 2) ; font-size: calc(42.2px / 2); font-weight: 600; line-height: calc(55px / 2);  
}
.mobile-crypto-low-fee-linked-inner {
    position: relative; margin-left: 20px; width: calc(100% - 40px); float: left; border-radius: calc(37.98px / 2); height: calc(379.8px / 2); background: white
}
.mobile-crypto-low-fee-linked-icon {
    width: calc(363px / 2); height: calc(121px / 2); position: absolute; right: calc(50px / 2); bottom: calc(50px / 2)
}


.main-crypto-low-fee-linked-inner {
    width: 100%; height: 100%; background-image: v-bind(_getBG); background-repeat: no-repeat; background-position: center; background-size: 80% auto; 
}
.main-crypto-low-fee-linked-titles {
    word-wrap: break-word; white-space: initial; margin-right: 52px; margin-left: 52px; margin-top: 49px; color: #191B1E; font-size: 37.411px; font-style: normal;  letter-spacing: 0.2px;
}
.main-crypto-low-fee-linked-outer {
    margin-left: 36px; position: relative; width: 610px; border-radius: 22.447px; float: left; height: 534px; background-color: white;
}
.main-crypto-low-fee-subtitle {
    position: absolute; height: 119.715px; right: 51px; bottom: 48px; color: #FFF; font-size: 112.233px; font-style: normal; font-weight: 600; line-height: 119.715px; letter-spacing: 0.2px;
}
.main-crypto-low-fee-title {
    color: #191B1E; word-wrap: break-word; white-space: initial; margin-right: 52px; margin-left: 52px; margin-top: 49px; color: white; font-size: 37.411px; font-style: normal; font-weight: 600; line-height: 44.893px;  letter-spacing: 0.2px;
}
.main-crypto-low-fee-inner {
    position: relative; width: 334px; border-radius: 22.447px; float: left; height: 534px;  background: linear-gradient(135deg, #6D23E5 0%, rgba(109, 35, 229, 0.00) 100%), #191B1E;
}
</style>

<script>
import { vueDetectMobile } from '@/components/VueDetectMobile.js'

export default {
    name: 'MainAbroadPaymentsComponent',
    components: { 
    },
    computed: {
        _getBG: function() {
            return `url('/img/link-wallet.png')`
        }
    },
    data() {
        return {
            _mobile: false
        }
    },
    async mounted() {    
        this._mobile = vueDetectMobile()
    }
}
</script>