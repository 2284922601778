<template>
    <div v-if="_mobile" style="width: 100%; float: left; ">
        <div class="main-money-send--outer">
            <div class="main-money-send--inner">
                <div class="main-money-send--title" style="color: white;">
                    <div>Отправка и получение денег</div>
                    <div style="color: #FFE374;">в разных валютах</div>
                </div>
            </div>
        </div>        
    </div>

    <div v-if="!_mobile" class="main-money-send-outer">
        <div class="main-money-send-inner">
            <div class="main-money-send-bg">
                <div class="main-money-send-title">
                    <div>{{ getLocale('home_send_money_1') }}</div>
                    <div style="color: #FFE374;">{{ getLocale('home_send_money_2') }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.main-money-send--title {
    word-wrap: break-word; 
    white-space: initial; 
    margin-right: calc(52px / 2); 
    margin-left: calc(52px / 2); 
    margin-top: calc(52px / 2) ;
    font-size: calc(42.2px / 2);
    font-weight: 600; 
    line-height: calc(55px / 2);  
    letter-spacing: 0.2px;
}
.main-money-send--outer {
    width: 100%; float: left; margin-top: 75px; height: 534px;
    background: linear-gradient(287deg, #1D4A8D 0%, rgba(29, 74, 141, 0.00) 100%), #191B1E;
    border-radius: calc(37.98px / 2); 
    margin-top: calc(33px / 2);
    height: calc(900px / 2); 
    margin-left: 20px; 
    width: calc(100% - 40px); 
}
.main-money-send--inner {    
    position: relative; 
    width: 100%;
    height: 100%;;     
    background-image: v-bind(_getBGMobile); 
    background-repeat: no-repeat; background-position: bottom 50px right 20px; background-size: auto calc(680px / 2);
}
@media only screen and (max-width: 360px) {
    .main-money-send--inner {    
        position: relative; 
        width: 100%;
        height: 100%;;     
        background-image: v-bind(_getBGMobile); 
        background-repeat: no-repeat; background-position: bottom 50px right 20px; background-size: auto calc(680px / 2.2);
    }
}
.main-money-send-title {
    color: #FFF; font-size: 37.411px; font-style: normal; font-weight: 600; line-height: 49.881px; letter-spacing: 0.2px;
}
.main-money-send-bg {
    width: 100%; height: 100%; background-image: v-bind(_getBG); background-repeat: no-repeat; background-position: bottom right 36px; background-size: auto 100%; 
}
.main-money-send-inner {
    padding-left: 51px; padding-top: 48px; width: calc(100% - 51px); height: calc(100% - 48px); border-radius: 22.447px; background: linear-gradient(315deg, #2860B5 0%, rgba(60, 82, 115, 0.00) 100%), #191B1E;
}
.main-money-send-outer {
    width: 100%; float: left; margin-top: 34px; height: 534px;
}
</style>

<script>
import Localization from '@/components/localization.js'
import { vueDetectMobile } from '@/components/VueDetectMobile.js'

export default {
    name: 'MainAbroadPaymentsComponent',
    components: { 
    },
    computed: {
        _getBG: function() {
            return `url('/img/p2p.png')`
        },
        _getBGMobile: function() {
            return `url('/img/p2p-mobile.png')`
        }
    },
    data() {
        return {
            _mobile: false
        }
    },
    async mounted() {    
        this._mobile = vueDetectMobile()
    },  
  methods: {
    getLocale(id) {
        return Localization.getLocaleString(id)       
    },
  }
}
</script>