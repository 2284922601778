<template>
    <div v-if="_mobile" style="width: 100%; float: left;">
        <div class="mobile-crypto-wallet-inner">
            <div class="mobile-crypto-wallet-title">
                <div style="font-weight: 600; color: white">Использование<br/>криптокошелька</div> 
                <div style="font-weight: 600;color: #52F3B9">совместно с<br/>дебетовой картой</div>
            </div>
        </div>
    </div>

    <div v-if="!_mobile" style="width: 100%; float: left; margin-top: 75px; height: 534px;">
        <div style="position: relative; width: 100%; height: 100%; border-radius: 22.447px; background-color: #2f2c33; overflow: hidden;">          
            <div style="padding-left: 51px; padding-top: 48px; width: 100%; height: 100%; background-image: url('/img/crypto-debit.png'); background-repeat: no-repeat; background-size: cover; ">
                <div style="word-wrap: break-word; white-space: initial; font-size: 37.411px; font-style: normal;  letter-spacing: 0.2px;">
                    <div style="font-weight: 600; color: white">Использование<br/>криптокошелька</div> 
                    <div style="font-weight: 600;color: #52F3B9">совместно с<br/>дебетовой картой</div>            
                </div> 
            </div>

        </div>
    </div>
</template>

<style scoped>
.mobile-crypto-wallet-title {
    word-wrap: break-word; 
    white-space: initial; 
    margin-right: calc(52px / 2); 
    margin-left: calc(52px / 2); 
    margin-top: calc(52px / 2) ;
    font-size: calc(42.2px / 2);
    font-weight: 600; 
    line-height: calc(55px / 2);  
    letter-spacing: 0.2px;
}
.mobile-crypto-wallet-inner {
    margin-top: calc(33px / 2);
    position: relative; 
    margin-left: 20px; 
    width: calc(100% - 40px); 
    float: left; 
    border-radius: calc(37.98px / 2); 
    height: calc(900px / 2); 
    background: white;    
    background-image: v-bind(_getBGMobile); 
    background-repeat: no-repeat; background-position: bottom 0% right calc(50px / 2); background-size: auto calc(461.061px / 2);
    background-color: #323137
}
</style>

<script>
import { vueDetectMobile } from '@/components/VueDetectMobile.js'

export default {
    name: 'MainAbroadPaymentsComponent',
    components: { 
    },
    computed: {
        _getBG: function() {
            return `url('/img/crypto-debit.png')`
        },
        _getBGMobile: function() {
            return `url('/img/crypto-debit-mobile.png')`
        }
    },
    data() {
        return {
            _mobile: false
        }
    },
    async mounted() {    
        this._mobile = vueDetectMobile()
    }
}
</script>