<template>
    <div v-if="_mobile" style="width: 100%; float: left; margin-top: 20px;">
        <div class="mobile-debid-card-components-outer">
            <div class="mobile-debid-card-components-inner">
                <div class="mobile-debid-card-components-title" style="color: white;">
                    Дебетовая карта  
                </div>
            </div>
        </div>        
    </div>

    <div v-if="!_mobile" class="main-debid-card-components-outer">
        <div class="main-debid-card-components-inner">
            <div class="main-debid-card-components-bg">
                <div class="main-debid-card-components-title">
                    Дебетовая карта            
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.mobile-debid-card-components-title {
    word-wrap: break-word; 
    white-space: initial; 
    margin-right: calc(52px / 2); 
    margin-left: calc(52px / 2); 
    margin-top: calc(52px / 2) ;
    font-size: calc(42.2px / 2);
    font-weight: 600; 
    line-height: calc(55px / 2);  
    letter-spacing: 0.2px;
}
.mobile-debid-card-components-outer {
    width: 100%; float: left; margin-top: 75px; height: 534px;
    background: linear-gradient(287deg, #1D4A8D 0%, rgba(29, 74, 141, 0.00) 100%), #191B1E;
    border-radius: calc(37.98px / 2); 
    margin-top: calc(33px / 2);
    height: calc(900px / 2); 
    margin-left: 20px; 
    width: calc(100% - 40px); 
}
.mobile-debid-card-components-inner {    
    position: relative; 
    width: 100%;
    height: 100%;;     
    background-image: v-bind(_getBG); 
    background-repeat: no-repeat; background-position: bottom 25px right 0%; background-size: auto calc(664.371px / 2);
}


.main-debid-card-components-title {
    color: #FFF; font-size: 37.411px; font-style: normal; font-weight: 600; line-height: 49.881px; letter-spacing: 0.2px;
}
.main-debid-card-components-bg {
    width: 100%; height: 100%; background-image: v-bind(_getBG); background-repeat: no-repeat; background-position: top 50% right 5%; background-size: auto 90%; 
}
.main-debid-card-components-inner {
    padding-left: 51px; padding-top: 48px; width: calc(100% - 51px); height: calc(100% - 48px); border-radius: 22.447px; background: linear-gradient(315deg, #1D4A8D 0%, rgba(29, 74, 141, 0.00) 100%), #191B1E;
}
.main-debid-card-components-outer {
    width: 100%; float: left; margin-top: 75px; height: 534px;
}
</style>

<script>
import { vueDetectMobile } from '@/components/VueDetectMobile.js'

export default {
    name: 'MainAbroadPaymentsComponent',
    components: { 
    },
    computed: {
        _getBG: function() {
            return `url("/img/hawala-card.png")`
        }
    },
    data() {
        return {
            _mobile: false
        }
    },
    async mounted() {    
        this._mobile = vueDetectMobile()
    }
}
</script>