export default {
    constructor() {
        console.log(4444)
    },
    getCurrentLocale() {
        var locale = 'ru';  

        if (localStorage) {
            var default_locale = localStorage.getItem('default_locale') 
            
            if(default_locale) {
                locale = default_locale
            }
        }

        return  locale
    },
    getLocaleString(id) {     
        var locale = 'ru';  
        if (localStorage) {
            var default_locale = localStorage.getItem('default_locale') 
            
            if(default_locale) {
                locale = default_locale
            }
        }

        if(locale in this.locales)
        {
            return this.locales[locale][id]
        }
        return this.locales['en'][id]        
    },
    locales: {
        'en': {
            'menu_desc': 'Description',
            'menu_cards': 'Cards',
            'menu_transfers': 'Transfers',
            'menu_calc': 'Calc',
            'menu_about': 'About',
            'main_hawala_title_1': 'Modern banking service',
            'main_hawala_title_2': 'for those who want:',
            'main_payments_title_1': 'Safely send money to another country',
            'main_payments_title_2': 'Pay for foreign services',
            'main_abroad_payments_title_1': 'Pay by card',
            'main_abroad_payments_title_2': 'Abroad',
            'main_abroad_payments_title_3': 'no limits',
            'home_all_func': 'All functions in one application',
            'home_all_transfers': 'Transfers',
            'home_ordering_title_1': 'Download Hazeena',
            'home_ordering_title_2': 'Зарегистрируйтесь',
            'home_ordering_title_3': 'Submit your application',
            'home_app_download': 'in English',
            'home_send_money_1': 'Sending and receiving money',
            'home_send_money_2': 'in different currencies',
            'home_common_1': 'Common accounts',
            'home_common_2': 'separate payment',
            'home_common_3': 'Linking Apple Pay and Google Pay',
            'home_transfers_1': 'Cross-border transfers',
            'home_transfers_2': 'From Russia to the CIS countries, Europe, Asia, USA',
            'home_transfers_3': 'Instant commission calculation',
            'home_transfers_4': 'Any currency',
            'home_transfers_5': 'Instant enrollment',
            'home_transfers_6': 'Payment is available immediately after sending',
        },
        'ru': {
            'menu_desc': 'Описание',
            'menu_cards': 'Карты',
            'menu_transfers': 'Переводы',
            'menu_calc': 'Калькулятор',
            'menu_about': 'О нас',
            'main_hawala_title_1': 'Современный банковский сервис',
            'main_hawala_title_2': 'для тех, кто хочет:',
            'main_payments_title_1': 'Безопасно отправлять деньги в другую страну',
            'main_payments_title_2': 'Оплачивать иностранные сервисы',
            'main_abroad_payments_title_1': 'Расплачиваться картой',
            'main_abroad_payments_title_2': 'за границей',
            'main_abroad_payments_title_3': 'без ограничений',
            'home_all_func': 'Все функции в одном приложении',
            'home_all_transfers': 'Переводы',
            'home_ordering_title_1': 'Скачайте Hazeena',
            'home_ordering_title_2': 'Register',
            'home_ordering_title_3': 'Подайте заявку',
            'home_app_download': 'на русском языке',
            'home_send_money_1': 'Отправка и получение денег',
            'home_send_money_2': 'в разных валютах',
            'home_common_1': 'Общие счета',
            'home_common_2': 'раздельная оплата',
            'home_common_3': 'Привязка Apple Pay и Google Pay',
            'home_transfers_1': 'Трансграничные переводы',
            'home_transfers_2': 'Из России в страны СНГ, Европу, Азию, США',
            'home_transfers_3': 'Мгновенный расчет комиссии',
            'home_transfers_4': 'Любая валюта',
            'home_transfers_5': 'Мгновенное зачисление',
            'home_transfers_6': 'Перевод доступен сразу после отправки',
            'FAQ': 'Частые вопросы',
        },
        'uz': {
            'menu_desc': 'Tavsif',
            'menu_cards': 'Kartalar',
            'menu_transfers': 'Transferlar',
            'menu_calc': 'Kalkulyator',
            'menu_about': 'Biz haqimizda',
            'main_hawala_title_1': 'Zamonaviy bank xizmati',
            'main_hawala_title_2': 'istaganlar uchun:',
            'main_payments_title_1': 'Boshqa davlatga xavfsiz pul o\'tkazing',
            'main_payments_title_2': 'Chet el xizmatlari uchun to\'lov',
            'main_abroad_payments_title_1': 'Karta orqali to\'lash',
            'main_abroad_payments_title_2': 'Chet elda',
            'main_abroad_payments_title_3': 'chegaralar yo\'q',
            'home_all_func': 'Barcha funktsiyalar bitta ilovada',
            'home_all_transfers': 'Transferlar',
            'home_ordering_title_1': 'Hazeena-ni yuklab oling',
            'home_ordering_title_2': 'Ro\ʻyxatdan o\ʻtish',
            'home_ordering_title_3': 'Arizangizni yuboring',
            'home_app_download': 'o\'zbek tilida',
            'home_send_money_1': 'Pul yuborish va qabul qilish',
            'home_send_money_2': 'turli valyutalarda',
            'home_common_1': 'Umumiy hisoblar',
            'home_common_2': 'alohida to\'lov',
            'home_common_3': 'Apple Pay va Google Payni ulash',
            'home_transfers_1': 'Transchegaraviy o\'tkazmalar',
            'home_transfers_2': 'O\‘zbekistondan MDH davlatlari, Yevropa, Osiyo, AQShga',
            'home_transfers_3': 'Tez komissiyani hisoblash',
            'home_transfers_4': 'Har qanday valyuta',
            'home_transfers_5': 'Darhol ro\'yxatdan o\'tish',
            'home_transfers_6': 'To\'lov yuborilgandan so\'ng darhol amalga oshiriladi',
        }
    }
}

String.prototype.formatUnicorn = String.prototype.formatUnicorn ||
function () {
    "use strict";
    var str = this.toString();
    if (arguments.length) {
        var t = typeof arguments[0];
        var key;
        var args = ("string" === t || "number" === t) ?
            Array.prototype.slice.call(arguments)
            : arguments[0];

        for (key in args) {
            str = str.replace(new RegExp("\\{" + key + "\\}", "gi"), args[key]);
        }
    }

    return str;
};