<template>
    <div v-if="!_mobile" style="width: 100%; float: left; margin-top: 75px; height: 520px;">
        <div style="position: relative; width: 100%; height: 100%; border-radius: 22.447px; background-color: white; overflow: hidden;">     
            <div style="width: 100%; float: left; height: 150px; position: relative;">
                <div style="width: 100%; float: left; margin-left: 50px; margin-top: 25px">
                    <span>Страна назначения</span>
                </div>
                <div class="calc-country-select-box" v-on:click="openCountries()">
                    <div class="calc-select-box-icon">
                        <div :style="getIconCountry" class="calc-flag-box">
                            
                        </div>                        
                    </div>
                    <div class="calc-select-box-title">
                        <div>
                            <span style="">{{ getCountryName }}</span>
                        </div>
                    </div>
                </div>

                <div v-if="_isCountrySelect" style=" overflow-y: scroll; z-index: 999; border-radius: 15px; border: 2px solid #C5C5C5; width: calc(100% - 80px); max-height: 400px; background-color: white; position: absolute; top: 54px; margin-left: 40px;margin-right: 40px;">
                    <div v-on:click="selectCountry(item.code)" v-for="(item, index) in countries" v-bind:key="index" style="cursor: pointer;height: 75px; width: 100%; border-bottom: 1px solid #C5C5C5;">
                        <div class="calc-select-box-icon">
                            <div :style="iconCountry(item.country)" class="calc-flag-box">
                                
                            </div>
                        </div>
                        <div class="calc-select-box-title">
                        <div>
                            <span style="">{{ item.country }}</span>
                        </div>
                    </div>
                    </div>
                </div>
            </div> 

            <div style="width: 100%; float: left;  height: 150px; ">
                <div style="width: 20%; float: left;  margin-top:25px; margin-left: 40px; ; position: relative;">
                    <div style="width: 100%; float: left; margin-left: 10px">
                        <span>Валюта отправки</span>
                    </div>
                    <div class="calc-select-box-outer"  v-on:click="openCurrencyFrom()">
                        <div class="calc-select-box-icon">
                            <div :style="getIconCurrencyFrom" class="calc-flag-box">
                                
                            </div>                        
                        </div>
                        <div class="calc-select-box-title">
                            <div>
                                <span>{{ getCurrencyFrom }}</span>
                            </div>
                        </div>
                    </div>

                    <div v-if="_isCurrencyFrom" class="select-box-currency">
                        <div v-on:click="selectCurrencyFrom(item.code)" v-for="(item, index) in currencies" v-bind:key="index" style="cursor: pointer;height: 75px; width: 100%; border-bottom: 1px solid #C5C5C5;">
                            <div class="calc-select-box-icon">
                                <div :style="iconCountry(item.country)" class="calc-flag-box">
                                    
                                </div>
                            </div>
                            <div class="calc-select-box-title">
                                <div>
                                    <span style="">{{ item.currency }}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div style="width: calc(60% - 40px - 40px - 40px - 10px); float: left;  margin-top:25px; margin-left: 25px; ">
                    <div style="width: 100%; float: left; margin-left: 10px">
                        <span>Сумма перевода</span>
                    </div>
                    <div class="calc-select-box-outer">
                        <input @change="onChangeAmountFrom($event)" v-model.number="amount_from" type="text" placeholder="Сумма перевода" class="amount_from"/>
                    </div>
                </div>
                <div style="width: 20%; float: left;  margin-top:25px; margin-left: 25px;  position: relative;">
                    <div style="width: 100%; float: left; margin-left: 10px">
                        <span>Валюта получения</span>
                    </div>
                    <div class="calc-select-box-outer" v-on:click="openCurrencyTo()">
                        <div class="calc-select-box-icon">
                            <div :style="getIconCurrencyTo" class="calc-flag-box">
                                
                            </div>                        
                        </div>
                        <div class="calc-select-box-title">
                            <div>
                                <span>{{ getCurrencyTo }}</span>
                            </div>
                        </div>
                    </div>

                    <div v-if="_isCurrencyTo" class="select-box-currency">
                        <div v-on:click="selectCurrencyTo(item.code)" v-for="(item, index) in currencies" v-bind:key="index" style="cursor: pointer;height: 75px; width: 100%; border-bottom: 1px solid #C5C5C5;">
                            <div class="calc-select-box-icon">
                                <div :style="iconCountry(item.country)" class="calc-flag-box">
                                    
                                </div>
                            </div>
                            <div class="calc-select-box-title">
                                <div>
                                    <span style="">{{ item.currency }}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div style="width: 100%; float: left; height: 150px; margin-top: 35px;">
                <div style="background: rgba(197, 197, 197, 0.20); border-radius: 22.447px; height: 150px; width: calc(100% - 80px); margin-left: 40px; float: left;">
                    <div style="width: 50px; height: 100%; float: left; margin-left: 30px; position: relative;">
                        <img src="/img/calc/sum.png" style="width: 100%;position:absolute; top:50%; left:50%; margin-top:-25px; margin-left:-25px;"/>
                    </div>
                    <div style="float: left; height: 100%; margin-left: 20px;align-items: center; display: flex; justify-content: center;">
                        <div style="">
                            <div>
                                <span>Итого к оплате:</span>
                            </div>
                            <div style="margin-top: 5px;">
                                <span style="font-style: normal; font-weight: 600; font-size: 30px;">{{ _total_amount_from }}</span>
                            </div>
                        </div>                        
                    </div>

                    <div style="width: 50px; height: 100%; float: left; margin-left: 60px; position: relative;">
                        <img src="/img/calc/fee.png" style="width: 100%;position:absolute; top:50%; left:50%; margin-top:-25px; margin-left:-25px;"/>
                    </div>

                    <div style="float: left; height: 100%; margin-left: 20px;align-items: center; display: flex; justify-content: center;">
                        <div style="">
                            <div>
                                <span>Комиссия:</span>
                            </div>
                            <div style="margin-top: 5px;">
                                <span style="font-style: normal; font-weight: 600; font-size: 30px;">{{ _tariff_amount }}</span>
                            </div>
                        </div>                        
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<style scoped>
.select-box-currency {
    overflow-y: scroll; z-index: 999; border-radius: 15px; border: 2px solid #C5C5C5; width: 100%; max-height: 250px; background-color: white; position: absolute; top: 25px
}
.amount_from {
    border: none; width: calc(100% - 40px); margin-left: 20px;font-style: normal; font-weight: 600; font-size: 25px; height: calc(100% - 4px);
}
textarea:focus, input:focus{
    outline: none;
}
.calc-select-box-outer {
    border-radius: 15px; border: 2px solid #C5C5C5; background: #FFF; width: 100%; float: left; height: 75px;; margin-top: 10px; cursor: pointer;
}
.calc-select-box-title span {
    font-style: normal; font-weight: 600; font-size: 25px;
}
.calc-select-box-title {
    float: left; height: 100%;margin-left: 15px; position: relative;align-items: center; display: flex; justify-content: center;
}
.calc-select-box-icon  {
    width: 50px; height: 100%; float: left; margin-left: 15px; position: relative;align-items: center; display: flex; justify-content: center;
}
.calc-country-select-box {
    cursor: pointer; margin-left: 40px; border-radius: 15px; border: 2px solid #C5C5C5; background: #FFF; width: calc(100% - 80px); float: left; height: 75px;; margin-top: 10px
}
.calc-flag-box {
    width: 40px; height: 40px; overflow: hidden;border-radius: 20px; 
    background-position: center;
    background-size: auto 100%; background-repeat: no-repeat;
}
</style>

<script>
// @ is an alias to /src
import { vueDetectMobile } from '@/components/VueDetectMobile.js'


export default {
  name: 'MainCalc',
  components: {

  },
  computed: {
        getCountryName() {
            var country = this.countries.filter(x => x.code == this._selected_country)[0]

            return country.country
        },
        getIconCountry() {
            var country = this.countries.filter(x => x.code == this._selected_country)[0]

            return {           
                'background-image': `url(/img/calc/flags/flag-of-${country.country}.png)`
            }
        },
        getIconCurrencyFrom() {
            var currency = this.currencies.filter(x => x.code == this._selected_currency_from)[0]

            return {           
                'background-image': `url(/img/calc/flags/flag-of-${currency.country}.png)`
            }
        },
        getCurrencyFrom() {
            var currency = this.currencies.filter(x => x.code == this._selected_currency_from)[0]

            return currency.currency
        },
        getCurrencyTo() {
            var currency = this.currencies.filter(x => x.code == this._selected_currency_to)[0]

            return currency.currency
        },
        getIconCurrencyTo() {
            var currency = this.currencies.filter(x => x.code == this._selected_currency_to)[0]

            return {           
                'background-image': `url(/img/calc/flags/flag-of-${currency.country}.png)`
            }
        }
    },
  data() {
    return {
        _total_amount_from: '--- ---',
        _tariff_amount: '--- ---',
        amount_from: null,
        _isCountrySelect: false,
        _isCurrencyFrom: false,
        _isCurrencyTo: false,
      _mobile: false,
      _selected_country: 182,
      _selected_currency_from: 57,
      _selected_currency_to: 56,
      currencies: [
        // {
        //     "currency": "EUR", "code": 2, "country": ""
        // }, 
        {
            "currency": "RUB", "code": 56  , "country": "Russia"                     
        },
        {
            "currency": "USD", "code": 58, "country": "USA"
        },
        {
            "currency": "UZS", "code": 57, "country": "Uzbekistan"
        }
      ],
      countries: [
        {
            "country": "Albania", "code": 1
        },
        {
            "country": "Andorra", "code": 4
        },{
            "country": "Argentina", "code": 9
        },{
            "country": "Armenia", "code": 10
        },{
            "country": "Australia", "code": 13
        },{
            "country": "Austria", "code": 14
        },{
            "country": "Azerbaijan", "code":  15
        },{
            "country": "Belarus", "code": 20
        },{
            "country": "Belgium", "code":21
        },{
            "country": "Brazil", "code": 30
        },{
            "country": "Bulgaria", "code": 33
        },{
            "country": "Canada", "code": 38
        },{
            "country": "Chile", "code": 44
        },{
            "country": "China", "code": 45
        },{
            "country": "Croatia", "code": 55
        },
        {
            "country": "Cyprus", "code":  57
        },{
            "country": "Czech Republic", "code": 58
        },{
            "country": "Denmark", "code": 59
        },{
            "country": "Egypt", "code": 64
        },{
            "country": "England", "code": 66
        },{
            "country": "Estonia", "code": 69
        },{
            "country": "Finland", "code": 75
        },{
            "country": "France", "code": 76
        },
        {
            "country": "Georgia", "code":  82
        },
        {
            "country": "Germany", "code":  83
        },{
            "country": "Greece", "code": 86
        },{
            "country": "Hong Kong (China)", "code": 98
        },{
            "country": "Hungary", "code": 99
        },{
            "country": "India", "code": 102
        },{
            "country": "Indonesia", "code": 103
        },{
            "country": "Ireland", "code": 106
        },{
            "country": "Israel", "code": 108    
        },{
            "country": "Italy", "code": 109        
        },{
            "country": "Japan", "code": 111     
        },{
            "country": "Kazakhstan", "code": 113        
        },{
            "country": "Korea", "code": 116
        },{
            "country": "Kyrgyzstan", "code": 119        
        },{
            "country": "Latvia", "code": 121        
        },{
            "country": "Liechtenstein", "code": 126        
        },{
            "country": "Lithuania", "code": 127        
        },{
            "country": "Luxembourg", "code": 128        
        },{
            "country": "Macau (China)", "code": 129        
        },{
            "country": "Macedonia", "code": 130        
        },{
            "country": "Malaysia", "code": 133        
        },{
            "country": "Malta", "code": 136        
        },{
            "country": "Mexico", "code": 142        
        },{
            "country": "Moldova", "code": 144        
        },{
            "country": "Monaco", "code": 145        
        },{
            "country": "Montenegro", "code": 252        
        },{
            "country": "Netherlands", "code": 154        
        },{
            "country": "New Zealand", "code": 157        
        },{
            "country": "Norway", "code": 164        
        },{
            "country": "Philippines", "code": 174        
        },{
            "country": "Poland", "code": 176        
        },{
            "country": "Portugal", "code": 177        
        },{
            "country": "Qatar", "code": 179        
        },{
            "country": "Romania", "code": 181        
        },{
            "country": "Russia", "code": 182        
        },{
            "country": "Saudi Arabia", "code": 192        
        },{
            "country": "Serbia", "code": 251        
        },{
            "country": "Singapore", "code": 197        
        },{
            "country": "Slovakia", "code": 98        
        },{
            "country": "Slovenia", "code": 199        
        },{
            "country": "Spain", "code": 205        
        },{
            "country": "Sweden", "code": 211        
        },{
            "country": "Switzerland", "code": 212        
        },{
            "country": "Tajikistan", "code": 215        
        },{
            "country": "Thailand", "code": 217        
        },{
            "country": "Turkey", "code": 224        
        },{
            "country": "United Arab Emirates", "code": 231        
        },{
            "country": "USA", "code": 233        
        },{
            "country": "Uzbekistan", "code": 236        
        },{
            "country": "Vietnam", "code":  240
        }
        
      ]
    }
  },
  async mounted() {    
    this._mobile = vueDetectMobile()    
  },

  created() {

  },
  methods: {
    iconCountry(country_name) {
        console.log('*** iconCountry ' + country_name)
        var country = this.countries.filter(x => x.country == country_name)[0]

        console.log(country)

        return {           
            'background-image': `url(/img/calc/flags/flag-of-${country.country.replace(' ', '_')}.png)`
        }
    },
    openCountries() {
        console.log('*** openCountries')
        this._isCountrySelect = true;
        this._isCurrencyFrom = false;
        this._isCurrencyTo = false;
    },
    selectCountry(code) {
        this._isCountrySelect = false;
        this._selected_country = code
    },
    selectCurrencyFrom(currency) {
        this._selected_currency_from =  currency
        this._isCurrencyFrom = false;
    }, 
    selectCurrencyTo(currency) {
        this._selected_currency_to =  currency
        this._isCurrencyTo = false;
    }, 
    openCurrencyFrom() {
        this._isCurrencyFrom = true;
        this._isCountrySelect = false;
        this._isCurrencyTo = false;
    }, 
    openCurrencyTo() {
        this._isCurrencyTo = true;
        this._isCurrencyFrom = false;
        this._isCountrySelect = false;
    }, 
    calculateRates() {
        /*
            curl 'http://95.130.227.103:8080/cfsapi/client/exchange?Amount=100&CountryTo=9&CurrencyFrom=56&CurrencyTo=58' \
            -H 'Content-Type: application/json'
        */
    },
    async onChangeAmountFrom(event) {

        console.log('*** onChangeAmountFrom')

        var amountRaw = event.target.value;

        var amount = parseFloat(amountRaw)

        this.amount_from = amount

        if(this.timeout) clearTimeout(this.timeout);

        this.timeout = setTimeout(async () => {
            await this.calculate_rate();
        }, 300);

    },
    moneyFormat(amount, currency) {
      return (amount).toLocaleString('en-US', {
        style: 'currency',
        currency: currency,
      })
    },
    async calculate_rate() {

        if(!this.amount_from) {
            return
        }

        // this._is_loading = true;

        let url = `${process.env.VUE_APP_API}/client/exchange?Amount=${this.amount_from}&CountryTo=${this._selected_country}&CurrencyFrom=${this._selected_currency_from}&CurrencyTo=${this._selected_currency_to}`;

        // var body = JSON.stringify({              
        //     "currency_from": this.currency_from,
        //     "currency_to": this.currency_to,
        //     "amount": this.amount_from
        // })

        let response = await fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            // "Access-Control-Allow-Origin" : "*"
        } })
        .catch(e => {
            console.log(e);
            return
        });

        if(!response) {
            return 
        }

        // this._is_loading = false;

        if (response.ok) 
        {            
            //{"data":{"amount":"100.000000000000000000","tariffamount":"1.000000000000000000","tarifrule":"324","rate":"91.887850000000000000","totalamountfrom":"101.000000000000000000","totalamountto":"9280.672850"}}

            let json = await response.json();
            console.log(json)

            var data = json['data']

            var totalamountfrom = parseFloat(data['totalamountfrom'])
            var tariffamount = parseFloat(data['tariffamount'])

            var currency = this.currencies.filter(x => x.code == this._selected_currency_from)[0]

            this._total_amount_from = this.moneyFormat(totalamountfrom, currency.currency)
            this._tariff_amount = this.moneyFormat(tariffamount, currency.currency)

            // this.amount_to = json['amount_to'] / 100
        }
    }
  }
}
</script>