<template>
    <div v-if="_mobile" style="width: 100%; float: left; margin-top: 20px">
        <div style="position: relative; margin-left: 20px; width: calc(100% - 40px); float: left; border-radius: calc(37.98px / 2); height: calc(379.8px / 2); background: white">
            <div style=" color: #191B1E;" class="mobile-common-accounts-title">
                <div style="font-weight: 600;">
                    Общие счета,<br/>
                    раздельная<br/>
                    оплата
                </div>                 
            </div>
            <div style="width: calc(300px / 2); height: calc(220px / 2); position: absolute; right: calc(51px / 2); bottom: calc(48px / 2);">
                <img src="/img/shared-payment.png" style="width: 100%"/>
            </div>
        </div>
    </div>

    <div v-if="!_mobile" style="width: 100%; float: left; margin-top: 34px; height: 534px;">
        <div style="position: relative; width: calc(60%); border-radius: 22.447px; float: left; height: 534px; background-color: white;">
            <div style="width: 100%; height: 100%; background-image: url('/img/shared-payment.png'); background-repeat: no-repeat; background-position: bottom 70px right 40px; background-size: 369px auto; ">
                <div style="margin-left: 51px; margin-top: 48px; color: #000; font-size: 37.411px; font-style: normal; font-weight: 600; line-height: 49.881px; letter-spacing: 0.2px;">
                    {{ getLocale('home_common_1') }},<br/> {{ getLocale('home_common_2') }}
                </div>
            </div>
        </div>

        <div style="position: relative; width: calc(40% - 35px); border-radius: 22.447px; float: right; height: 534px; background: linear-gradient(134deg, #515D71 0%, rgba(81, 93, 113, 0.00) 100%), #191B1E;">
            <div style="margin-left: 51px; margin-right: 51px; margin-top: 48px; color: #fff; font-size: 37.411px; font-style: normal; font-weight: 600; line-height: 49.881px; letter-spacing: 0.2px;">
                {{ getLocale('home_common_3') }}
            </div>
        </div>
    </div>
</template>

<style scoped>
.mobile-common-accounts-title {
    word-wrap: break-word; 
    white-space: initial; 
    margin-right: calc(52px / 2); 
    margin-left: calc(52px / 2); 
    margin-top: calc(52px / 2) ;
    font-size: calc(42.2px / 2);
    font-weight: 600; 
    line-height: calc(55px / 2);  
    letter-spacing: 0.2px;
}
</style>

<script>
import Localization from '@/components/localization.js'
import { vueDetectMobile } from '@/components/VueDetectMobile.js'

export default {
    name: 'MainAbroadPaymentsComponent',
    components: { 
    },
    data() {
        return {
            _mobile: false
        }
    },
    async mounted() {    
        this._mobile = vueDetectMobile()
    },
    methods: {
        getLocale(id) {
            return Localization.getLocaleString(id)       
        },
    }
}
</script>