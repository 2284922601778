<template>
    <div v-if="_mobile" style="width: 100%; float: left; margin-top: 20px">
        <div style="position: relative; margin-left: 20px; width: calc(100% - 40px); float: left; border-radius: calc(37.98px / 2); height: calc(379.8px / 2); background: white">
            <div style=" color: #191B1E;" class="mobile-send-money-title">
                <div style="font-weight: 600;">Криптокошелек</div> 
                <div style="margin-top:5px; line-height: 20px;font-weight: 400; font-size: calc(29.54px / 2)">в связке<br/>с дебетовой картой</div>
            </div>
            <div class="mobile-send-money-wallet-icon" style="">
                <img src="/img/crypto-wallet.png" style="width: 100%"/>
            </div>
        </div>

        <div style="margin-top: 20px; position: relative; margin-left: 20px; width: calc(100% - 40px); float: left; border-radius: calc(37.98px / 2); height: calc(379.8px / 2); background: linear-gradient(143deg, rgba(255, 255, 255, 0.50) 0%, rgba(255, 255, 255, 0.00) 98.85%), #C6B7FF;">
            <div style=" color: #191B1E;" class="mobile-send-money-title">
                Оплата покупок<br/>
                в интернете
            </div>
            <div class="mobile-send-money-icon">
                <img src="/img/internet-payment.png" style="width: 100%"/>
            </div>
        </div>
    </div>

    <div v-if="!_mobile" style="width: 100%; float: left; margin-top: 34px;">
        <div style="position: relative; width: calc(50% - 18px); border-radius: 22.447px; float: left; height: 534px; background-color: white;">          
            <div style="word-wrap: break-word; white-space: initial; margin-right: 52px; margin-left: 52px; margin-top: 49px; color: #191B1E; font-size: 37.411px; font-style: normal;  letter-spacing: 0.2px;">
                <div style="font-weight: 600;">Криптокошелек</div> 
                <div style="font-weight: 400; font-size: 27.934px;">в связке<br/>с дебетовой картой</div>            
            </div>   
            
            <div style="position: absolute; width: 320px; left: 72px; bottom: 45px">
                <img src="/img/crypto-wallet.png" style="width: 100%"/>
            </div>          
        </div>

        <div style="position: relative; width: calc(50% - 18px); border-radius: 22.447px; background: linear-gradient(136deg, rgba(255, 255, 255, 0.50) 0%, rgba(255, 255, 255, 0.00) 100%), #C6B7FF;; float: left; height: 534px; margin-left: 36px;">
            <div style="color: #191B1E; word-wrap: break-word; white-space: initial; margin-right: 52px; margin-left: 52px; margin-top: 49px; color: #191B1E; font-size: 37.411px; font-style: normal; font-weight: 600; line-height: 44.893px;  letter-spacing: 0.2px;">
                Оплата покупок
                в интернете
            </div>

            <div style="position: absolute; width: 310px; left: 103px; bottom: 45px">
                <img src="/img/internet-payment.png" style="width: 100%"/>
            </div>
        </div>
    </div>
</template>

<style scoped>
.mobile-send-money-wallet-icon {
    width: calc(258px / 2); height: calc(209px / 2); position: absolute; right: calc(51px / 2); bottom: calc(48px / 2);
}
.mobile-send-money-icon {
    width: calc(242px / 2); height: calc(251px / 2); position: absolute; right: calc(51px / 2); bottom: calc(48px / 2);
}
@media only screen and (max-width: 360px) {
    .mobile-send-money-icon {
        width: calc(242px / 2.5); height: calc(251px / 2); position: absolute; right: calc(51px / 2); bottom: 0px;
    }
    .mobile-send-money-wallet-icon {
        width: calc(258px / 2.5); height: calc(209px / 2.5); position: absolute; right: calc(51px / 2); bottom: calc(48px / 2);
    }
}
.mobile-send-money-title {
    word-wrap: break-word; 
    white-space: initial; 
    margin-right: calc(52px / 2); 
    margin-left: calc(52px / 2); 
    margin-top: calc(52px / 2) ;
    font-size: calc(42.2px / 2);
    font-weight: 600; 
    line-height: calc(55px / 2);  
    letter-spacing: 0.2px;
}
</style>

<script>
import { vueDetectMobile } from '@/components/VueDetectMobile.js'

export default {
    name: 'MainAbroadPaymentsComponent',
    components: { 
    },
    computed: {
        _getBG: function() {
            return `url("/img/hawala-card.png")`
        }
    },
    data() {
        return {
            _mobile: false
        }
    },
    async mounted() {    
        this._mobile = vueDetectMobile()
    }
}
</script>