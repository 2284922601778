<template>
    <div v-if="_mobile" style="width: 100%; float: left; margin-top: 20px">
        <div class="mobile-crypto-buy-sell-nolimit-inner">
            <div class="mobile-crypto-buy-sell-nolimit-title">
                <div style="font-weight: 600; color: #000; line-height: calc(44.893px / 2)">Покупка и продажа криптовалюты</div> 
                <div style="font-weight: 600; color: #9F3AEE">без ограничений</div>              
            </div>
        </div>
    </div>

    <div v-if="!_mobile" class="main-crypto-buy-sell-outer">
        <div class="main-crypto-buy-sell-inner">          
            <div class="main-crypto-buy-sell-nolimit">
                <div class="main-crypto-buy-sell-nolimit-text">
                    <div class="main-crypto-buy-sell-nolimit-1">Покупка и продажа криптовалюты</div> 
                    <div class="main-crypto-buy-sell-nolimit-2">без ограничений</div>                    
                </div>            
            </div>        
        </div>

        <div class="main-crypto-buy-sell-questions-block ">          
            <div class="main-crypto-buy-sell-questions-title">
                Частые вопросы
            </div>

            <div class="main-crypto-buy-sell-questions-img">
                <img src="/img/any-questions.png"/>
            </div>
        </div>
    </div>
</template>

<style scoped>
.mobile-crypto-buy-sell-nolimit-title {
    margin-right: calc(52px / 2);  margin-left: calc(52px / 2); margin-top: calc(52px / 2) ; font-size: calc(42.2px / 2); font-weight: 600; line-height: calc(55px / 2);  
}
.mobile-crypto-buy-sell-nolimit-inner {
    position: relative; margin-left: 20px; width: calc(100% - 40px); float: left; border-radius: calc(37.98px / 2); height: calc(379.8px / 2); background: white;
    background-image: v-bind(_getBGMobile); 
    background-repeat: no-repeat; background-position: right bottom; background-size: auto calc(314.012px / 2); 
}
.mobile-crypto-buy-sell-nolimit-icon {
    width: calc(363px / 2); height: calc(121px / 2); position: absolute; right: calc(50px / 2); bottom: calc(50px / 2)
}

.main-crypto-buy-sell-nolimit-2 {
    font-weight: 600; font-size: 37.411px; color: #9F3AEE
}
.main-crypto-buy-sell-nolimit-1 {
    font-weight: 600; color: #000; line-height: 44.893px
}
.main-crypto-buy-sell-nolimit-text {
    word-wrap: break-word; white-space: initial; margin-right: 52px; margin-left: 52px; margin-top: 49px; color: #191B1E; font-size: 37.411px; font-style: normal;  letter-spacing: 0.2px;
}
.main-crypto-buy-sell-nolimit {
    width: 100%; height: 100%; 
    background-image: v-bind(_getBG); 
    background-repeat: no-repeat; background-position: center; background-size: 100% auto; 
}
.main-crypto-buy-sell-inner {
    position: relative; width: 610px; border-radius: 22.447px; float: left; height: 534px; background-color: white;
}
.main-crypto-buy-sell-questions-img img {
    width: 100%;
}
.main-crypto-buy-sell-questions-img {
    position: absolute; height: 34px; width: 62px; left: 51px; bottom: 48px;
}
.main-crypto-buy-sell-questions-title {
    color: #191B1E; word-wrap: break-word; white-space: initial; margin-right: 52px; margin-left: 52px; margin-top: 49px; color: #191B1E; font-size: 37.411px; font-style: normal; font-weight: 600; line-height: 44.893px;  letter-spacing: 0.2px;
}
.main-crypto-buy-sell-questions-block {
    position: relative; width: 334px; border-radius: 22.447px; float: left; height: 534px; margin-left: 36px; background: linear-gradient(315deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0.00) 100%), #DEE3EC; background-blend-mode: soft-light, normal;
}
.main-crypto-buy-sell-outer {
    width: 100%; float: left; margin-top: 34px;
}
</style>

<script>
import { vueDetectMobile } from '@/components/VueDetectMobile.js'

export default {
    name: 'MainCryptoBuySell',
    components: { 
    },
    computed: {
        _getBG: function() {
            return `url("/img/buy-sell.png")`
        },
        _getBGMobile: function() {
            return `url("/img/buy-sell-mobile.png")`
        }
    },
    data() {
        return {
            _mobile: false
        }
    },
    async mounted() {    
        this._mobile = vueDetectMobile()
    }
}
</script>