<template>
  <router-view/>
</template>

<style>
html, body, #app, main {
  height: 100%;
  background: #F0F1F2; 
  margin: 0px;
  /*    */
}

/* html, body {
  overflow-x: hidden;
} */

#app {
  font-family: Helvetica, Verdana, Geneva, Tahoma, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;

  width: 100%;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

a {
  text-decoration: none;
  color: #2c3e50;
  font-family: Helvetica, Verdana, Geneva, Tahoma, sans-serif;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.hw-container {
    max-width: 980px;
    width: 980px;
    padding-left: calc(var(--bs-gutter-x) * .5);
    padding-right: calc(var(--bs-gutter-x) * .5);
    margin-left: auto;
    margin-right: auto;
}
.doc-container-outer {
    border-radius: 22px; background: #FFF; width: calc(100% - 120px); float: left; margin-top: 80px; padding: 60px;
}

.doc-container-title {
    width: 100%; float: left
}

.doc-container-title span {
    font-size: 30px; font-style: normal; font-weight: 600;
}

.doc-container-inner {
    width: 100%; float: left; margin-top: 40px;
}

@media only screen and (max-width: 460px) {
    .doc-container-outer {
        border-radius: 22px; background: #FFF; width: calc(100% - 40px); float: left; margin-top: 80px; padding: 20px;
    }

    .doc-container-title {
        width: 100%; float: left
    }

    .doc-container-title span {
        font-size: 30px; font-style: normal; font-weight: 600;
    }

    .doc-container-inner {
        width: 100%; float: left; margin-top: 40px;
}
}
</style>
