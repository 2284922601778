<template>
    <div v-if="_mobile" style="width: 100%; float: left; margin-top: 20px">
        <div style="position: relative; margin-left: 20px; width: calc(100% - 40px); float: left; border-radius: calc(37.98px / 2); height: calc(379.8px / 2); background: white">
            <div style="color: white; margin-right: calc(52px / 2);  margin-left: calc(52px / 2); margin-top: calc(52px / 2) ; font-size: calc(42.2px / 2); font-weight: 600; line-height: calc(55px / 2);  ">
                <div style="font-weight: 600; color: #191B1E">Возможность пополнения</div>
                <div style="color: #191B1E; font-weight: 400; font-size: calc(29.54px / 2); margin-top:5px; line-height: 20px;">Моментальное пополнение<br/>с карты МИР<br/>или криптокошелька</div>              
            </div>
            <div class="mobile-card-features-instant-topup" >
                <img src="/img/instant-topup.png" style="width: 100%"/>
            </div>
        </div>

        <div style="margin-top:20px; position: relative; margin-left: 20px; width: calc(100% - 40px); float: left; border-radius: calc(37.98px / 2); height: calc(379.8px / 2); background: linear-gradient(322deg, #616161 0%, rgba(0, 0, 0, 0.00) 101.3%), #191B1E;">
            <div style="color: white; margin-right: calc(52px / 2);  margin-left: calc(52px / 2); margin-top: calc(52px / 2) ; font-size: calc(42.2px / 2); font-weight: 600; line-height: calc(55px / 2);  ">
                <div style="font-weight: 600;">Привязывается<br/>к Apple Pay</div> 
                <div style="color: #BDBDBD; font-weight: 400; font-size: calc(29.54px / 2); margin-top:5px; line-height: 20px;">Покупки в интернете<br/>и офлайн-магазинах<br/>за границей</div>              
            </div>
            <div style="width: calc(185px / 2); height: calc(118px / 2); position: absolute; right: calc(51px / 2); bottom: calc(48px / 2);">
                <img src="/img/instant-apple.png" style="width: 100%"/>
            </div>
        </div>
    </div>

    <div v-if="!_mobile" style="width: 100%; float: left; margin-top: 34px;">
        <div style="position: relative; width: calc(50% - 18px); border-radius: 22.447px; float: left; height: 534px; background: linear-gradient(315deg, #616161 0%, rgba(0, 0, 0, 0.00) 100%), #191B1E;">          
            <div style="word-wrap: break-word; white-space: initial; margin-right: 52px; margin-left: 52px; margin-top: 49px;  font-size: 37.411px; font-style: normal;  letter-spacing: 0.2px;">
                <div style="font-weight: 600; color: white">Привязывается к Apple Pay и Google Pay</div> 
                <div style="margin-top: 10px; font-weight: 400; font-size: 27.934px; color: #BDBDBD">Покупки в интернете и офлайн-магазинах за границей</div>            
            </div>   
            
            <div style="position: absolute; width: 100.108px; height: 60.828px; right: 51px; bottom: 48px">
                <img src="/img/instant-apple.png" style="width: 100%"/>
            </div>          
        </div>

        <div style="position: relative; width: calc(50% - 18px); border-radius: 22.447px; background-color: white; float: left; height: 534px; margin-left: 36px;">
            <div style="word-wrap: break-word; white-space: initial; margin-right: 52px; margin-left: 52px; margin-top: 49px;  font-size: 37.411px; font-style: normal;  letter-spacing: 0.2px;">
                <div style="font-weight: 600; color: #191B1E">Возможность пополнения</div> 
                <div style="margin-top: 10px; font-weight: 400; font-size: 27.934px; color: #191B1E">Моментальное пополнение с карты МИР или криптокошелька</div>            
            </div> 

            <div class="main-card-features-instant-topup">
                <img src="/img/instant-topup.png" style="width: 100%"/>
            </div>
        </div>
    </div>
</template>

<style scoped>
.mobile-card-features-instant-topup {
    width: calc(426px / 2.25); height: calc(323px / 2.25); position: absolute; right: 0px; bottom: -18px
}
@media only screen and (max-width: 360px) {
    .mobile-card-features-instant-topup {
        width: calc(426px / 2.7); height: calc(323px / 2.7); position: absolute; right: 0px; bottom: -18px
    }
}
.main-card-features-instant-topup {
    position: absolute; width: 310px; left: 103px; bottom: 0px; overflow: hidden;
}
</style>

<script>
import { vueDetectMobile } from '@/components/VueDetectMobile.js'

export default {
    name: 'MainAbroadPaymentsComponent',
    components: { 
    },
    data() {
        return {
            _mobile: false
        }
    },
    async mounted() {    
        this._mobile = vueDetectMobile()
    }
}
</script>