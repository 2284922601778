<template>
    <div class="mobile-ordering-container" v-if="_mobile">
        <div class="mobile-ordering-container-inner" style="padding-top: calc(86px / 2)">

            <div class="mobile-ordering-step-container">
                <div class="mobile-ordering-step-icon" style="background-image: url('/img/ordering-1.png');"></div>                
            </div>

            <div class="mobile-ordering-step-title">
                <span>Скачайте Hazeena</span>
            </div>

            <div class="mobile-ordering-separator-container ">
                <div class="mobile-ordering-separator-icon">
                    <img src="/img/ordering-sep.png" style="width: 100%"/>
                </div>
            </div>

            <div class="mobile-ordering-step-container" style="margin-top: 20px;">
                <div class="mobile-ordering-step-icon" style="background-image: url('/img/ordering-2.png');"></div>                
            </div>
            <div class="mobile-ordering-step-title">
                <span>Зарегистрируйтесь</span>
            </div>
            <div class="mobile-ordering-separator-container ">
                <div class="mobile-ordering-separator-icon">
                    <img src="/img/ordering-sep.png" style="width: 100%"/>
                </div>
            </div>

            <div class="mobile-ordering-step-container" style="margin-top: 20px;">
                <div class="mobile-ordering-step-icon" style="background-image: url('/img/ordering-3.png');"></div>                
            </div>
            <div class="mobile-ordering-step-title">
                <span>Подайте заявку</span>
            </div>
        </div>
    </div>

    <div class="ordering-container" v-if="!_mobile">
        <div class="ordering-container-inner">
            <div class="ordering-step-container">
                <div class="ordering-step-icon" style="background-image: url('/img/ordering-1.png');"></div>
                <div class="ordering-step-title">
                    <span>{{ getLocale('home_ordering_title_1') }}</span>
                </div>
            </div>

            <div class="ordering-separator-container ">
                <div class="ordering-separator-icon">
                    <img src="/img/ordering-sep.png" style="width: 100%"/>
                </div>
            </div>

            <div class="ordering-step-container">
                <div class="ordering-step-icon" style="background-image: url('/img/ordering-2.png');"></div>
                <div class="ordering-step-title">
                    <span>{{ getLocale('home_ordering_title_2') }}</span>
                </div>
            </div>

            <div class="ordering-separator-container ">
                <div class="ordering-separator-icon">
                    <img src="/img/ordering-sep.png" style="width: 100%"/>
                </div>
            </div>

            <div class="ordering-step-container">
                <div class="ordering-step-icon" style="background-image: url('/img/ordering-3.png'); "></div>
                <div class="ordering-step-title">
                    <span>{{ getLocale('home_ordering_title_3') }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.mobile-ordering-separator-icon {
    width: 14px; height: 14px; position: relative; display: table; margin: 0 auto;
}
.mobile-ordering-separator-container {
    width: 100%; height: 14px; float: left; margin-top: 20px;
}
.mobile-ordering-step-title {
    float: left; height: 30px; line-height: 30px;  text-align: center; width: 100%; margin-top: 12px;
}
.mobile-ordering-step-title span {
    color: #191B1E; font-size: calc(42.2px / 2); font-style: normal; font-weight: 600; letter-spacing: 0.2px;
}
.mobile-ordering-step-icon {
    width: 70px; height: 70px; background-repeat: no-repeat; background-size: 100% auto;display: table; margin: 0 auto;
}
.mobile-ordering-step-container {
    width: 100%; height: 70px; float: left;
}
.mobile-ordering-container {
    width: calc(100% - 20px); float: left; margin-top: 20px; height: 490px; margin-left: 20px
}
.mobile-ordering-container-inner {    
    position: relative;     
    width: calc(100% - 20px); 
    float: left; 
    border-radius: calc(37.98px / 2); 
    height: 100%; 
    background: white;            
}
.ordering-separator-icon {
    margin-left: 38px; width: 25px; height: 25px; position: relative; top: 50%; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%); transform: translateY(-50%);
}
.ordering-separator-container {
    width: calc(100% - 100px); height: 73px;
}
.ordering-container-inner {
    width: calc(100% - 100px); height: calc(100% - 50px); border-radius: 22.447px; background-color: white; padding-top: 50px; padding-left: 50px; padding-right: 50px;
}
.ordering-container {
    width: 100%; float: left; margin-top: 75px; height: 534px;
}
.ordering-step-container {
    width: calc(100% - 100px); height: 100px;
}
.ordering-step-title {
    float: left; height: 100px; line-height: 100px; margin-left: 55px;
}
.ordering-step-title span {
    color: #191B1E; font-size: 37.411px; font-style: normal; font-weight: 400; letter-spacing: 0.2px;
}
.ordering-step-icon {
    width: 100px; height: 100px; float: left; background-repeat: no-repeat; background-size: 100% auto;
}
</style>

<script>
import Localization from '@/components/localization.js'
// @ is an alias to /src
import { vueDetectMobile } from '@/components/VueDetectMobile.js'

export default {
  name: 'HomeView',
  components: {
    
  },
  data() {
    return {
      _mobile: false
    }
  },
  async mounted() {    
    this._mobile = vueDetectMobile()
  },  
  methods: {
    getLocale(id) {
        return Localization.getLocaleString(id)       
    },
  }
}
</script>