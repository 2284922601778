<template>
  <MainTemplate selected="">
    <div class="main-outer-container">
      <div v-if="_mobile" class="main-inner-container">
        <!-- <MainCardsComponent/> -->
        <MainHawalaComponent/>
        <MainPaymentsComponent/>
        <!-- <MainAbroadPaymentsComponent/> -->
        <!-- <MainTariffsComponent/> -->
        <!-- <MainChapterComponent title="Оформление карты"/> --> 
        <MainOrderingStepsComponent/>
        <!-- <MainCardUsageComponent/> -->
        <MainAppDownloadComponent/>
        <MainChapterComponent title="Все функции в одном приложении"/>
        <!-- <MainDebitCardComponent/> -->
        <!-- <MainSendMoneyComponent/> -->
        <MainMoneyAnySend/>
        <MainCommonAccounts/>
        <!-- <MainChapterComponent title="Карты"/> -->
        <!-- <MainInstantCard/> -->
        <!-- <MainCardFeatures/> -->
        <MainChapterComponent title="Переводы"/>
        <MainInterTransfers/>
        <MainFeeLow/>
        <MainFlashTopup/>
        <!-- <MainChapterComponent title="Криптовалюта"/> -->
        <!-- <MainCryptoWallet/> -->
        <!-- <MainCryptoLowFee/> -->
        <!-- <MainCryptoBuySell/>  -->
      </div>

      <div v-if="!_mobile" class="hw-container main-inner-container">
        <!-- <MainCardsComponent/> -->
        <MainHawalaComponent/>
        <MainPaymentsComponent/>
        <!-- <MainAbroadPaymentsComponent/>
        <MainTariffsComponent/>
        <MainChapterComponent title="Оформление карты" id="cards_order_chapter"/> -->
        <MainOrderingStepsComponent/>
        <!-- <MainCardUsageComponent/> -->
        <MainAppDownloadComponent/>
        <MainChapterComponent :title="getLocale('home_all_func')" id="all_functions_chapter"/>
        <!-- <MainDebitCardComponent/> -->
        <!-- <MainSendMoneyComponent/> -->
        <MainMoneyAnySend/>
        <MainCommonAccounts/>
        <!-- <MainChapterComponent title="Карты" id="cards_chapter"/>
        <MainInstantCard/>
        <MainCardFeatures/> -->
        <MainChapterComponent :title="getLocale('home_all_transfers')" id="transfers_chapter"/>
        <MainInterTransfers/>
        <MainFeeLow/>
        <MainFlashTopup/>
        <MainCalculationComponent/>
        <MainChapterComponent  :title="getLocale('menu_calc')" id="calc_chapter"/>
        <MainCalc/>
        <!-- <MainChapterComponent title="Криптовалюта" id="crypto_chapter"/>
        <MainCryptoWallet/>
        <MainCryptoLowFee/>
        <MainCryptoBuySell/>       -->
        <MainMozaicComponent/>
      </div>
    </div>
  </MainTemplate>
</template>

<style>
.main-inner-container {
  text-align: left;  width: 100%; height: 100%; position: relative;
}
.main-outer-container {
  text-align: left;  width: 100%; height: 100%;
}
.main-chapter {
  width: 100%; float: left; margin-top: 165px; text-align: center;
}
.main-chapter-title {
  color: #191B1E; text-align: center; font-size: 52.375px; font-style: normal; font-weight: 600; line-height: 63.848px; letter-spacing: 0.2px;
}
.mobile-chapter {
  width: calc(100% - 40px); float: left; margin-top: calc(211px / 3); text-align: center; margin-left: 20px;
}
.mobile-chapter-title {
  color: #191B1E; text-align: center; font-size: calc(67.52px / 2); font-style: normal; font-weight: 600; line-height: calc(67.52px / 2); letter-spacing: 0.2px;
}
</style>

<script>
import Localization from '@/components/localization.js'

// @ is an alias to /src
import { vueDetectMobile } from '@/components/VueDetectMobile.js'
import MainTemplate from '@/components/global/MainTemplate.vue'
import MainChapterComponent from '@/components/main/MainChapterComponent.vue'
import MainTariffsComponent from '@/components/main/MainTariffsComponent.vue'
import MainOrderingStepsComponent from '@/components/main/MainOrderingStepsComponent.vue'
import MainMozaicComponent from '@/components/main/MainMozaicComponent.vue'
import MainCardsComponent from '@/components/main/MainCardsComponent.vue'
import MainHawalaComponent from '@/components/main/MainHawalaComponent.vue'
import MainPaymentsComponent from '@/components/main/MainPaymentsComponent.vue'
import MainAbroadPaymentsComponent from '@/components/main/MainAbroadPaymentsComponent.vue'
import MainCardUsageComponent from '@/components/main/MainCardUsageComponent.vue'
import MainAppDownloadComponent from '@/components/main/MainAppDownloadComponent.vue'
import MainDebitCardComponent from '@/components/main/MainDebitCardComponent.vue'
import MainSendMoneyComponent from '@/components/main/MainSendMoneyComponent.vue'
import MainMoneyAnySend from '@/components/main/MainMoneyAnySend.vue'
import MainCommonAccounts from '@/components/main/MainCommonAccounts.vue'
import MainInstantCard from '@/components/main/MainInstantCard.vue'
import MainCardFeatures from '@/components/main/MainCardFeatures.vue'
import MainInterTransfers from '@/components/main/MainInterTransfers.vue'
import MainFeeLow from '@/components/main/MainFeeLow.vue'
import MainFlashTopup from '@/components/main/MainFlashTopup.vue'
import MainCalculationComponent from '@/components/main/MainCalculationComponent.vue'
import MainCryptoWallet from '@/components/main/MainCryptoWallet.vue'
import MainCryptoLowFee from '@/components/main/MainCryptoLowFee.vue'
import MainCryptoBuySell from '@/components/main/MainCryptoBuySell.vue'
import MainCalc from '@/components/main/MainCalc.vue'

export default {
  name: 'HomeView',
  components: {
    MainTemplate,
    MainTariffsComponent,
    MainOrderingStepsComponent,
    MainMozaicComponent,
    MainCardsComponent,
    MainChapterComponent,
    MainHawalaComponent,
    MainPaymentsComponent,
    MainAbroadPaymentsComponent,
    MainCardUsageComponent,
    MainAppDownloadComponent,
    MainDebitCardComponent,
    MainSendMoneyComponent,
    MainMoneyAnySend,
    MainCommonAccounts,
    MainInstantCard,
    MainCardFeatures,
    MainInterTransfers,
    MainFeeLow,
    MainFlashTopup,
    MainCalculationComponent,
    MainCryptoWallet,
    MainCryptoLowFee,
    MainCryptoBuySell,
    MainCalc
  },
  data() {
    return {
      _mobile: false
    }
  },
  async mounted() {    
    this._mobile = vueDetectMobile()
  },

  created() {

  },
  methods: {
    getLocale(id) {
        return Localization.getLocaleString(id)       
    },
  }
}
</script>
