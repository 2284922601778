<template>
    <div v-if="_mobile" style="width: 100%; float: left;  margin-top: 30px;">
        <div id="mainCards" class="mobile-cards">
            <div class="mobile-component-card" id="mainCard" ref="mainCard" :style="getMainCardStyle">
                <div class="mobile-mainCardImg">
                    <img src="/img/block_1_img.png"/>
                </div>

                <div class="mobile-cards-desc-list">
                    <ul>
                        <li>Пополнение криптовалютой и МИР</li>
                        <li>Привязка Apple Pay и Google Pay</li>
                        <li>Виртуальные карты</li>
                        <li>Моментальный выпуск</li>                        
                    </ul>             
                </div>

                <div class="mobile-cards-button-next-container">
                    <div class="mobile-cards-button-next-inner">
                        <div class="mobile-cards-button-next-title">Карты</div>
                        <div class="mobile-cards-button-next-icon">
                            <img src="/img/array-right.png"/>
                        </div>            
                    </div>          
                </div>
            </div>

            <div class="mobile-component-crypto" id="mainCrypto" ref="mainCrypto" :style="getMainCryptoStyle">
                <div class="mobile-mainCryptoImg">
                    <img src="/img/block_1_img_2.png"/>
                </div>

                <div class="mobile-cards-desc-list">
                    <ul>
                        <li>Привязка криптокошелька к балансу дебетовой карты</li>
                        <li>Покупка и продажа криптовалюты</li>
                        <li>Безопасные переводы</li>
                        <li>Обмен на любую валюту</li>
                    </ul>             
                </div>

                <div class="mobile-cards-button-next-container">
                    <div class="mobile-cards-button-next-inner">
                        <div class="mobile-cards-button-next-title">Криптовалюта</div>
                        <div class="mobile-cards-button-next-icon">
                            <img src="/img/array-right.png"/>
                        </div>            
                    </div>          
                </div>
            </div>

            <div class="mobile-component-transfers" id="mainTransfers" ref="mainTransfers" :style="getMainTransfersStyle">
                <div class="mobile-mainTransfersImg">
                    <img src="/img/block_1_img_3.png"/>
                </div>

                <div class="mobile-cards-desc-list">
                    <ul>
                        <li>Трансграничные переводы</li>
                        <li>Мгновенное зачисление</li>
                        <li>Любая валюта</li>
                        <li>Комиссия 1%</li>
                    </ul>             
                </div>

                <div class="mobile-cards-button-next-container">
                    <div class="mobile-cards-button-next-inner">
                        <div class="mobile-cards-button-next-title">Переводы</div>
                        <div class="mobile-cards-button-next-icon">
                            <img src="/img/array-right.png"/>
                        </div>            
                    </div>          
                </div>
            </div>
        </div>
    </div>

    <div v-if="!_mobile" style="width: 100%; float: left;  margin-top: 100px;">
        <div id="mainCards" class="main-cards">
            <div class="main-component-card" id="mainCard" ref="mainCard" :style="getMainCardStyle">
                <div class="mainCardImg">
                    <img src="/img/block_1_img.png"/>
                </div>

                <div class="main-cards-desc-list">
                    <ul>
                        <li>Виртуальные карты</li>
                        <li>Моментальный выпуск</li>
                        <li>Пополнение криптовалютой и МИР</li>
                        <li>Привязка Apple Pay и Google Pay</li>
                    </ul>             
                </div>

                <div class="main-cards-button-next-container">
                    <div class="main-cards-button-next-inner">
                        <div class="main-cards-button-next-title">Карты</div>
                        <div class="main-cards-button-next-icon">
                            <img src="/img/array-right.png"/>
                        </div>            
                    </div>          
                </div>
            </div>

            <div class="main-component-crypto" id="mainCrypto" ref="mainCrypto" :style="getMainCryptoStyle">
                <div class="mainCryptoImg">
                    <img src="/img/block_1_img_2.png"/>
                </div>

                <div class="main-cards-desc-list">
                    <ul>
                        <li>Привязка криптокошелька к балансу дебетовой карты</li>
                        <li>Покупка и продажа криптовалюты</li>
                        <li>Безопасные переводы</li>
                        <li>Обмен на любую валюту</li>
                    </ul>             
                </div>

                <div class="main-cards-button-next-container">
                    <div class="main-cards-button-next-inner">
                        <div class="main-cards-button-next-title">Криптовалюта</div>
                        <div class="main-cards-button-next-icon">
                            <img src="/img/array-right.png"/>
                        </div>            
                    </div>          
                </div>
            </div>

            <div class="main-component-transfers" id="mainTransfers" ref="mainTransfers" :style="getMainTransfersStyle">
                <div class="mainTransfersImg">
                    <img src="/img/block_1_img_3.png"/>
                </div>

                <div class="main-cards-desc-list">
                    <ul>
                        <li>Трансграничные переводы</li>
                        <li>Мгновенное зачисление</li>
                        <li>Любая валюта</li>
                        <li>Комиссия 1%</li>
                    </ul>             
                </div>

                <div class="main-cards-button-next-container">
                    <div class="main-cards-button-next-inner">
                        <div class="main-cards-button-next-title">Переводы</div>
                        <div class="main-cards-button-next-icon">
                            <img src="/img/array-right.png"/>
                        </div>            
                    </div>          
                </div>
            </div>
        </div>

    </div> 

    <!-- start cards switcher -->

    <div v-if="_mobile" class="mobile-switcher-container">
        <div class="mobile-switcher-inner">
            <div ref="switcher_item_1" :class="currentCard == 0 ? 'main-switcher-item-active' : 'main-switcher-item-inactive'" class="mobile-switcher-item"></div>
            <div ref="switcher_item_2" :class="currentCard == 1 ? 'main-switcher-item-active' : 'main-switcher-item-inactive'" class="mobile-switcher-item"></div>
            <div ref="switcher_item_3" :class="currentCard == 2 ? 'main-switcher-item-active' : 'main-switcher-item-inactive'" class="mobile-switcher-item"></div>
        </div>
    </div>
    
    <div v-if="!_mobile" class="main-switcher-container">
        <div class="main-switcher-inner">
            <div ref="switcher_item_1" :class="currentCard == 0 ? 'main-switcher-item-active' : 'main-switcher-item-inactive'" class="main-switcher-item"></div>
            <div ref="switcher_item_2" :class="currentCard == 1 ? 'main-switcher-item-active' : 'main-switcher-item-inactive'" class="main-switcher-item"></div>
            <div ref="switcher_item_3" :class="currentCard == 2 ? 'main-switcher-item-active' : 'main-switcher-item-inactive'" class="main-switcher-item"></div>
        </div>
    </div>

    <!-- end cards switcher -->
</template>

<script>
// @ is an alias to /src
import { vueDetectMobile } from '@/components/VueDetectMobile.js'

export default {
    name: 'MainCardsComponent',
    components: {

    },
    data() {
        return {
            cardsQueue: [0, 980, 980],
            cardsDisplay: ['block', 'none', 'none'],
            cardsVisibility: ['visible', 'hidden', 'hidden'],
            currentCard: 0,
            _mobile: false,
            _card_offset: 0
        }
    },
    computed: {
        getMainCardStyle() {
            if(this.cardsDisplay[0] == 'none') {
                return {
                    display: 'none',                    
                }
            }

          return {           
            transform: `translateX(${this.cardsQueue[0]}px)`,
            transition: `transform 1.5s`,
            display: 'block',            
          }
        },
        getMainCryptoStyle() {
            if(this.cardsDisplay[1] == 'none') {
                return {
                    display: 'none',                    
                }
            }

          return {           
            transform: `translateX(${this.cardsQueue[1]}px)`,
            transition: `transform 1.5s`,
            display: 'block',            
          }
        },
        getMainTransfersStyle() {
            if(this.cardsDisplay[2] == 'none') {
                return {
                    display: 'none',                    
                }
            }

          return {           
            transform: `translateX(${this.cardsQueue[2]}px)`,
            transition: `transform 1.5s`,
            display: 'block',            
          }
        }
    },
    methods: {

        switchCards() {
            var secondSlideIdx = 0
            if(this.currentCard + 1 == this.cardsQueue.length) {
                secondSlideIdx = 0
            } else {
                secondSlideIdx = this.currentCard + 1
            }

            this.cardsDisplay[secondSlideIdx] = 'block'
            this.cardsVisibility[secondSlideIdx] = 'visible'

            setTimeout(() => {
                var slider1 = this.currentCard
                var slider2 = secondSlideIdx

                this.cardsQueue[this.currentCard] = this._card_offset * -1
                this.cardsQueue[secondSlideIdx] = 0

                this.currentCard = secondSlideIdx

                this.switchCards()

                setTimeout(() => {
                    this.cardsDisplay[slider1] = 'none'
                    this.cardsVisibility[slider1] = 'hidden'
                    this.cardsQueue[slider1] =  this._card_offset
                }, 2500)

            }, 5000)
        }
    },
    mounted() {    
        this._mobile = vueDetectMobile();

        if(this._mobile) {
            this._card_offset = window.screen.width;
            this.cardsQueue = [0, this._card_offset, this._card_offset]
        } else {
            this._card_offset = 980;
            this.cardsQueue = [0, 980, 980]
        }

        if(process.env.VUE_APP_DEBUG == 0) {
            this.switchCards()
        }       
        // 
    },
    watch: {            

    } 
}
</script>

<style scoped>
.mobile-switcher-item {
    width: calc(14px / 2); height: calc(14px / 2); background-color: #10253C; border-radius: calc(7px / 2); float: left; margin-left: calc(8px / 2); margin-right: calc(8px / 2);
}
.mobile-switcher-inner {
    width: calc(90px / 2); height: calc(14px / 2);
}
.mobile-switcher-container {
    width: 100%; float: left; text-align: center; display: flex; justify-content: center; margin-top: calc(33px / 2);
}
.mobile-cards-button-next-container {
    left: calc(42px / 2); bottom: calc(42px / 2); position: absolute; height: calc(67px / 2); border-radius: calc(33.76px / 2); background: #000; backdrop-filter: blur(0.9976270794868469px);
}
.mobile-cards-button-next-inner {
    padding-left: calc(33px / 2); padding-right: calc(33px / 2);
}
.mobile-cards-button-next-title {
    float: left; color: #FFF; font-size: calc(21.1px / 2); font-style: normal; font-weight: 600; line-height: calc(67px / 2); letter-spacing: 0.2px;
}
.mobile-cards-button-next-icon img {
    width: 16px
}
.mobile-cards-button-next-icon {
    float: right; line-height: calc(67px / 2); margin-left: 18px;
}

.main-switcher-item-active {
    opacity: 0.6;
}
.main-switcher-item-inactive {
    opacity: 0.25;
}
.main-switcher-item {
    width: 14px; height: 14px; background-color: #10253C; border-radius: 7px; float: left; margin-left: 8px; margin-right: 8px;
}
.main-switcher-inner {
    width: 90px; height: 14px;
}
.main-switcher-container {
    width: 100%; float: left; text-align: center; display: flex; justify-content: center; margin-top: 33px;
}
.main-cards-button-next-icon img {
    width: 33px
}
.main-cards-button-next-icon {
    float: right; line-height: 62.886px; margin-left: 18px;
}
.main-cards-button-next-title {
    float: left; color: #FFF; font-size: 19.953px; font-style: normal; font-weight: 600; line-height: 55.886px; letter-spacing: 0.2px;
}
.main-cards-button-next-inner {
    /* width: 100%; height: 100%;  */
    padding-left: 38px; padding-right: 38px;
}
.main-cards-button-next-container {
    left: 46px; bottom: 58px; position: absolute; height: 55.886px; border-radius: 43.896px; background: #000; backdrop-filter: blur(0.9976270794868469px);
}

.mobile-cards-desc-list {
    margin-left: -10px; padding-top: 10px; color: #FFF; font-size: calc(27.435px / 2); font-style: normal; font-weight: 600; line-height: calc(54.869px / 2); letter-spacing: 0.2px;
}
.main-cards-desc-list {
    padding-left: 25px; padding-top: 48px; color: #FFF; font-size: 29.54px; font-style: normal; font-weight: 600; line-height: 54.869px; letter-spacing: 0.2px;
}
.mobile-component-card, .mobile-component-crypto, .mobile-component-transfers {
    position: absolute; width: calc(100% - 40px); height: calc(506px / 2); border-radius: calc(37.98px / 2); top: 0px;
    margin-left: 20px;
}
.main-component-card, .main-component-crypto, .main-component-transfers {
    position: absolute; width: calc(100% - 20px); height: 485px; border-radius: 22.447px; top:0px;
}
.mobile-component-card {
    background: linear-gradient(77deg, #F9337A 9.8%, rgba(219, 28, 236, 0.25) 116%), #000; 
}
.mobile-component-crypto {
    background: linear-gradient(77deg, #6129D7 10.09%, rgba(233, 29, 212, 0.25) 111.67%), #000;
}
.mobile-component-transfers {
    background: linear-gradient(76deg, #27CA8F 8.77%, rgba(93, 23, 241, 0.25) 113.3%), #000;
}

.main-cards {
    width: 100%; height: 485px; overflow: hidden; position: relative;
}
.mobile-cards {
    width: 100%; height: calc(506px / 2); overflow: hidden; position: relative;
}

.main-component-card {
    background: linear-gradient(47deg, #F9337A 0%, rgba(219, 28, 236, 0.25) 100%), #000; 
}

.main-component-crypto {
    background: linear-gradient(46deg, #6129D7 0%, rgba(233, 29, 212, 0.25) 100%), #000;
}

.main-component-transfers {
    background: linear-gradient(46deg, #27CA8F 0%, rgba(93, 23, 241, 0.25) 100%), #000;
}

.mobile-mainCardImg {
    width: calc(355.499px / 2); height: calc(271.182px / 2); transform: rotate(-46.044deg); position: absolute; top: 108px; right: 0px
}
.mobile-mainCryptoImg {
    width: calc(294.542px / 2);
    height: calc(267.042px / 2);
    transform: rotate(-7.817deg);
    position: absolute; top: 108px; right: 0px
}
.mobile-mainTransfersImg {
    width: calc(386.552px / 2.2);
    height: calc(305.609px / 2.2);
    position: absolute; top: 90px; right: 10px
}

.mobile-mainCardImg img, .mobile-mainCryptoImg img, .mobile-mainTransfersImg img {
    width: 100%
}

.mainCardImg {
    width: 432.597px; height: 341.811px; transform: rotate(-65.447deg); position: absolute; top: 80px; right: -50px
}
.mainCryptoImg {
    width: 420px;  position: absolute; top: 85px; right: -20px
}

.mainTransfersImg {
    width: 432.597px;  position: absolute; top: 75px; right: -13px
}

.mainCardImg img, .mainCryptoImg img, .mainTransfersImg img {
    width: 100%
}
</style>