import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/company',
    name: 'about',
    component: function () {
      return import('../views/CompanyView.vue')
    }
  },
  {
    path: '/docs/data_protection',
    name: 'docs_data_protection',
    component: function () {
      return import('../views/DocsDataProtectionView.vue')
    }
  },
  {
    path: '/docs/privacy',
    name: 'docs_policy',
    component: function () {
      return import('../views/DocsDataProtectionView.vue')
    }
  },
  {
    path: '/docs/terms',
    name: 'docs_terms',
    component: function () {
      return import('../views/DocsTermsView.vue')
    }
  }
  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
